import React, { useMemo } from 'react';
import { TasksPanelContext } from './TasksPanelContext';
import { TasksPanelProviderProps } from './TasksPanelContext.types';

export const TasksPanelProvider = ({
  task,
  stage,
  children,
  setMode,
  setStage,
  setTask,
}: TasksPanelProviderProps) => {
  const contextValue = useMemo(() => {
    return {
      task,
      stage,
      setMode,
      setStage,
      setTask,
    };
  }, [stage, task, setMode, setStage, setTask]);

  return (
    <TasksPanelContext.Provider value={contextValue}>
      {children}
    </TasksPanelContext.Provider>
  );
};
