import { AvatarItem } from 'components/lib/Avatar/types';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { SelectUserGroupOption } from 'utils/types/selectInput.types';

export const useGroupAssigneesSelection = (assignedGroupIds: number[]) => {
  const intl = useIntl();

  const [selectedGroups, setSelectedGroups] = useState<AvatarItem[]>([]);

  const hasSelectedGroups = selectedGroups.length > 0;

  const getIsGroupAlreadySelected = (groupId: number) => {
    return selectedGroups.some(({ id }) => {
      return groupId === id;
    });
  };

  const getIsGroupAlreadyAssigned = (groupId: number) => {
    return assignedGroupIds.some(id => {
      return groupId === id;
    });
  };

  const getIsGroupDisabled = (groupId: number) => {
    return (
      getIsGroupAlreadySelected(groupId) || getIsGroupAlreadyAssigned(groupId)
    );
  };

  const getDisabledGroupText = (groupId: number) => {
    if (getIsGroupAlreadyAssigned(groupId)) {
      return intl.formatMessage({
        id: 'misc.alreadyAssigned',
        defaultMessage: 'Already assigned',
      });
    }

    if (getIsGroupAlreadySelected(groupId)) {
      return intl.formatMessage({
        id: 'misc.alreadySelected',
        defaultMessage: 'Already selected',
      });
    }

    return undefined;
  };

  const handleGroupSelect = (group: SelectUserGroupOption | undefined) => {
    if (!group) {
      return;
    }

    const { id, text } = group;

    setSelectedGroups(prev => [...prev, { id, userGroup: text }]);
  };

  const handleGroupRemove = async (groupId: string | number) => {
    setSelectedGroups(prev => prev.filter(({ id }) => groupId !== id));
  };

  return {
    selectedGroups,
    hasSelectedGroups,
    getIsGroupDisabled,
    getDisabledGroupText,
    handleGroupSelect,
    handleGroupRemove,
  };
};
