import React, { useCallback, useEffect, useState } from 'react';
import {
  ExpandedUserListProps,
  SelectGroupMemberOption,
} from './ExpandedUserList.types';
import { useIntl } from 'react-intl';
import { useExpandedUserListStyles } from './ExpandedUserList.styles';
import { USER_GROUP_USERS } from 'utils/endpoints';
import { generatePath } from 'react-router-dom';
import {
  UserGroupMembership,
  GroupMember,
} from 'utils/types/api/userGroups.types';
import { Loader } from 'components/lib/Loader';
import { AccountTypeOptions } from 'pages/Users/enums';
import { Input } from 'components/lib/Input';
import { SearchBoldIcon } from 'components/Icon';
import { debounce } from 'lodash';
import { useInfiniteScroll } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/hooks';
import { UserOrGroupSelectOptionType } from 'utils/types/selectInput.types';
import { MemoizedUsersGroupsListItemSelectAndDeselect } from 'components/UsersAndGroupsSelection/components/UsersGroupsListItemSelectAndDeselect';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext/UsersAndGroupsExpandablePickerContext';
import { SyncGroupToggle } from './components/SyncGroupToggle';
import { useUsersAndGroupsExpandablePickerStyles } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/components/UsersAndGroupsExpandablePicker/UsersAndGroupsExpandablePicker.styles';
import { UsersAndGroupsPickerRejectButton } from '../../../../../UsersAndGroupsPickerRejectButton';
import { MaxUsersSelectedTooltip } from '../../../MaxUsersSelectedTooltip';
import {
  USERS_AND_GROUPS_EXPANDED_SEARCH_FIELD_TESTID,
  USERS_AND_GROUPS_EXPANDED_USERS_LIST_TESTID,
  USERS_AND_GROUPS_SELECT_EXPANDED_DROPDOWN_CONTAINER_TESTID,
} from 'utils/testIds';

/**
 * Represents the right side panel content in UserAndGroupsExpandablePicker when a group got clicked
 */
export const ExpandedUserList = ({ userGroup }: ExpandedUserListProps) => {
  const intl = useIntl();
  const styles = useExpandedUserListStyles();
  const sharedStyles = useUsersAndGroupsExpandablePickerStyles();

  const {
    selectedItems,
    toggleItemSelectionState,
    editModeOptions,
    limits,
    isMaxUsersLimitReached,
    onGroupMembersLoad,
  } = useUsersAndGroupsExpandablePickerContext();

  const [searchText, setSearchText] = useState<string>('');

  const apiPath = generatePath(`${USER_GROUP_USERS}?membership=member`, {
    id: userGroup.id,
  });

  const { results, initialLoadComplete, onScroll } = useInfiniteScroll<
    SelectGroupMemberOption,
    GroupMember
  >({
    endpoint: apiPath,
    blockInitialLoad: false,
    searchText: searchText,
    responseToDataConversionFunction: groupMember => ({
      ...groupMember,
      type: UserOrGroupSelectOptionType.User,
      account_type: AccountTypeOptions.Standard,
      company: groupMember.company_name,
      is_deleted: false,
      text: `${groupMember.first_name} ${groupMember.last_name} (${groupMember.company_name})`,
      value: groupMember.id,
    }),
    itemUniqueIdGenerationFunction: item => `${item.type}-${item.id}`,
    onSuccessfulFetch: onGroupMembersLoad,
  });

  const debouncedSearch = useCallback(
    debounce((searchText: string) => {
      setSearchText(searchText);
    }, 200),
    []
  );

  const onSearchTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      debouncedSearch(event.target.value);
    },
    [debouncedSearch]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);
  const isSyncGroupMembers = !!selectedItems.groups.get(userGroup.id);

  return (
    <div
      className={styles.contentContainer}
      data-testid={USERS_AND_GROUPS_SELECT_EXPANDED_DROPDOWN_CONTAINER_TESTID}
    >
      <div className={styles.controlsContainer}>
        <Input
          prefix={<SearchBoldIcon size={12} className={styles.searchIcon} />}
          onChange={onSearchTextChange}
          className={styles.searchInput}
          data-testid={USERS_AND_GROUPS_EXPANDED_SEARCH_FIELD_TESTID}
          placeholder={intl.formatMessage({
            id: 'placeholders.searchForUsers',
            defaultMessage: 'Search for users',
          })}
        />
        {limits.isAllowedToSyncGroupMembers && (
          <SyncGroupToggle
            userGroup={userGroup}
            isSyncGroupMembers={isSyncGroupMembers}
          />
        )}
      </div>
      <div
        className={styles.userListContainer}
        onScroll={event => {
          event.stopPropagation();
          onScroll(event);
        }}
        data-testid={USERS_AND_GROUPS_EXPANDED_USERS_LIST_TESTID}
      >
        {!initialLoadComplete ? (
          <div className={sharedStyles.loadingContainer}>
            <Loader size='small' />
          </div>
        ) : (
          results.map(({ domId, item: user }) => {
            const isSelected =
              !!selectedItems.users.get(user.id) || isSyncGroupMembers;

            return (
              <MaxUsersSelectedTooltip
                isItemSelected={isSelected}
                key={user.id}
              >
                <MemoizedUsersGroupsListItemSelectAndDeselect
                  id={domId}
                  item={user}
                  handleClick={toggleItemSelectionState}
                  selected={isSelected}
                  isDisabled={
                    isSyncGroupMembers ||
                    !limits.isAllowedToSelectIndividualMembers ||
                    (!isSelected && isMaxUsersLimitReached)
                  }
                  searchText={searchText}
                  limitReached={false}
                  additionalText={
                    user.membership === UserGroupMembership.Owner
                      ? 'Group owner'
                      : undefined
                  }
                />
              </MaxUsersSelectedTooltip>
            );
          })
        )}
      </div>
      {editModeOptions && (
        <UsersAndGroupsPickerRejectButton
          onClick={editModeOptions.onRejection}
          isSaving={editModeOptions.isSaving}
        />
      )}
    </div>
  );
};
