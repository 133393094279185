import { UserAvatarData } from 'hooks/avatars/types';
import { orderBy } from 'lodash';

/**
 * Sorts user by full name.
 *
 * @param users Users to sort.
 *
 * @returns Alphatecially sorted list of users.
 */
export const sortUserAvatars = (users: UserAvatarData[]) => {
  return orderBy(users, user => {
    const fullName = `${user.firstName} ${user.lastName}`;

    return fullName.toLowerCase();
  });
};
