import { createUseStyles } from 'react-jss';

export const useTaskFormAssigneesSectionStyles = createUseStyles({
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',

    paddingBottom: '16px',
  },
  fieldWrapper: {
    display: 'flex',
    flexDirection: 'column',

    gap: '4px',
  },
});
