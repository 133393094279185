import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import {
  DEFAULT_PAGE_SIZE,
  DEFAULT_PAGE_SIZE_INFINITE_SCROLL,
  DEFAULT_SORT,
} from 'components/Table/Table.consts';
import { getParamValue } from 'utils/functions/getParamValue';
import TablesType from 'utils/Enums/TablesType';
import { useSelector } from 'react-redux';
import {
  getSelectedObjectClassId,
  getTablesState,
} from 'store/selectors/preferencesSelectors';
import { TableUrlParams } from 'utils/types/api/preferences.types';

export const useTableParams = (
  currentTable: TablesType | undefined,
  withoutSort: boolean | undefined,
  tablePageSizes: string[],
  defaultSortColumn?: string,
  withPagination: boolean | undefined = true
) => {
  const storedTablesState = useSelector(getTablesState);
  const objectClassId = useSelector(getSelectedObjectClassId);
  const isObjectRecordsTable = currentTable === TablesType.ObjectRecords;

  const storedCurrentTableState =
    currentTable && storedTablesState?.[currentTable];

  const { search: searchQuery } = useLocation();

  return useMemo<TableUrlParams>(() => {
    const params = queryString.parse(searchQuery);

    const objectRecordsSorting =
      objectClassId &&
      storedCurrentTableState?.sortById &&
      storedCurrentTableState?.sortById[objectClassId]
        ? storedCurrentTableState.sortById[objectClassId]
        : '';

    const storedSort =
      isObjectRecordsTable && objectRecordsSorting
        ? objectRecordsSorting
        : storedCurrentTableState?.sort || defaultSortColumn || DEFAULT_SORT;

    const parsedSort =
      searchQuery && params.sort && !defaultSortColumn
        ? (params.sort as string)
        : storedSort;

    const sort = withoutSort ? '' : parsedSort;

    const isInfinite =
      !!params.isInfinite ||
      !withPagination ||
      storedCurrentTableState?.isInfinite;

    if (isInfinite) {
      return {
        isInfinite,
        currentPage: 1,
        pageSize: DEFAULT_PAGE_SIZE_INFINITE_SCROLL,
        sort: defaultSortColumn ?? sort,
        sortById: storedCurrentTableState?.sortById,
      };
    }

    const storedPageSize =
      storedCurrentTableState?.pageSize || DEFAULT_PAGE_SIZE;

    const storedCurrentPage = storedCurrentTableState?.currentPage || 1;

    const pageSize = searchQuery
      ? getParamValue(DEFAULT_PAGE_SIZE, params.pageSize as string, [
          tablePageSizes.includes(params.pageSize as string),
        ])
      : storedPageSize;

    const currentPage = searchQuery
      ? getParamValue(1, params.currentPage as string, [
          tablePageSizes.includes(params.pageSize as string),
        ])
      : storedCurrentPage;

    return {
      currentPage,
      pageSize,
      sort,
      isInfinite: undefined,
      sortById: storedCurrentTableState?.sortById,
    };
  }, [
    searchQuery,
    storedCurrentTableState,
    defaultSortColumn,
    withoutSort,
    withPagination,
    tablePageSizes,
    isObjectRecordsTable,
    objectClassId,
  ]);
};
