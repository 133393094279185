import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetCurrentFilters } from 'store/actions/filtersActions';
import { getCurrentTableCustomOffset } from 'store/selectors/generalSelectors';
import { UseGeneratedParamsStringProps } from './useGeneratedParamsString.types';
import { useFiltersToQueryString } from './hooks';

/**
 * Allows to generate query string based on current pagination, sort and applied filters.
 *
 * @returns Function that generates query string based on current pagination, sort and applied filters.
 */
export const useGeneratedParamsString = ({
  pagination,
  sort,
  withFilters,
  classId,
  customTableName,
}: UseGeneratedParamsStringProps) => {
  const dispatch = useDispatch();

  const customOffset = useSelector(
    getCurrentTableCustomOffset(classId, customTableName)
  );
  const { getFiltersQueryString } = useFiltersToQueryString();

  const generateQueryString = useCallback(() => {
    const params: { [key: string]: string | number | undefined } = {
      limit: pagination.pageSize,
      offset:
        pagination.pageSize * pagination.currentPage -
        pagination.pageSize +
        customOffset,
      ordering: sort ? sort : undefined,
    };

    const queryParams = Object.keys(params)
      .filter(key => !!params[key])
      .map(key => key + '=' + params[key])
      .join('&');

    if (!withFilters) {
      return queryParams;
    }

    try {
      const filterParams = getFiltersQueryString();

      if (!!filterParams) {
        return `${queryParams}&${filterParams}`;
      }
    } catch {
      //if filters are corrupted we reset them
      dispatch(resetCurrentFilters());
    }

    return queryParams;
  }, [
    customOffset,
    pagination.currentPage,
    pagination.pageSize,
    sort,
    withFilters,
    dispatch,
    getFiltersQueryString,
  ]);

  return { generateQueryString };
};
