import React, { useEffect, useMemo, useRef } from 'react';
import { Table } from 'components/Table';
import LoadingWrapper from 'components/wrappers/LoadingWrapper';
import useCurrentTable from 'hooks/useCurrentTable';
import { RowsDisplay, TableWrapperProps } from '../Table.types';
import { TableContextProvider } from 'contexts/TableContext';
import { TableRowContextProvider } from 'contexts/TableRowContext';
import InfiniteTable from './InfiniteTable';
import { ActionConfirmationModal } from './ActionConfirmationModal';

const TableWrapper = <T extends object>({
  fetchMethod,
  dataSelector,
  getColumnsConfiguration,
  resetData,
  resetColumns,
  columnLabelKey,
  preventFromHideColumns,
  additionalHeaderActions,
  withFiltersDefault = true,
  customCells,
  withPagination,
  confirmationData,
  handleOrderChange,
  newButtonDisabled,
  handleSearch,
  children,
  setSelectedRow,
  getSelectedRow,
  onDoubleClick,
  onSingleClick,
  onActionCellClick,
  modalData,
  setModalData,
  withColumnConfiguration = true,
  defaultSearchValue,
  defaultIsVisibleBulkSelectionColumn,
  hasListPermissions = true,
  withoutSort = false,
  customDataGroups,
  preferencesId,
  defaultSortColumn,
  refetchMethodRef,
  loading,
  pageSizes,
  isPaginationUsingURL,
  rowsDisplay = RowsDisplay.FOLLOW_NUMBER_OF_RESULTS,
  preventInteractionWithColumns = false,
  preventOverflow = false,
  paginationRender,
  withBordersAroundTable = false,
  fullWidth = false,
  renderEmptyTable,
  renderHeaderLimit,
  resetPaginationRef,
  alwaysDisplayPagination,
  customTableName,
  customTableId = '',
  defaultColumnWidthsOverrides,
  customOverLimit,
  ...props
}: TableWrapperProps<T>): JSX.Element => {
  const {
    isInfinite,
    currentColumns,
    isFetching,
    data,
    sort,
    fetchData,
    density,
    columnsConfigurationLoading,
    currentTableName,
    ...rest
  } = useCurrentTable<T>({
    fetchMethod,
    dataSelector,
    getColumnsConfiguration,
    resetData,
    columnLabelKey,
    resetColumns,
    withPagination,
    handleSearch,
    defaultSearchValue,
    defaultIsVisibleBulkSelectionColumn,
    withoutSort,
    withFiltersDefault,
    preferencesId,
    customDataGroups,
    defaultSortColumn,
    loading,
    isPaginationUsingURL,
    pageSizes,
    resetPaginationRef,
    customTableName,
    defaultColumnWidthsOverrides,
  });
  const sortParams = useMemo(() => {
    const isDesc = sort?.charAt(0) === '-';

    return {
      id: isDesc ? sort.slice(1) : sort,
      desc: isDesc,
    };
  }, [sort]);

  const prevHoveredElem = useRef<HTMLDivElement>(null);
  const draggedId = useRef<number>();

  useEffect(() => {
    if (refetchMethodRef) refetchMethodRef.current = fetchData;
  }, [fetchData, refetchMethodRef]);

  return (
    <>
      <TableRowContextProvider
        value={{
          customCells,
          currentColumns,
          density,
          setSelectedRow,
          getSelectedRow,
          onDoubleClick,
          onSingleClick,
          onActionCellClick,
          prevHoveredElem,
          draggedId,
        }}
      >
        <TableContextProvider
          value={{
            isInfinite,
            sortParams,
            preventFromHideColumns,
            additionalHeaderActions,
            isFetching,
            data,
            sort,
            withPagination,
            withColumnConfiguration,
            fetchData,
            newButtonDisabled,
            handleOrderChange,
            hasListPermissions,
            withoutSort,
            customDataGroups,
            preferencesId,
            columnsConfigurationLoading,
            rowsDisplay,
            preventInteractionWithColumns,
            preventOverflow,
            paginationRender,
            withBordersAroundTable,
            renderEmptyTable,
            fullWidth,
            renderHeaderLimit,
            alwaysDisplayPagination,
            currentTableName: customTableName ?? currentTableName,
            customTableId,
            customOverLimit,
            ...rest,
            ...props,
          }}
        >
          <LoadingWrapper
            // key to reinitilize table state after change preferencesId
            key={`table-loader-${preferencesId ?? ''}`}
            loading={!!loading}
          >
            {isInfinite ? <InfiniteTable /> : <Table />}
          </LoadingWrapper>
          {children}
        </TableContextProvider>
      </TableRowContextProvider>
      {confirmationData && modalData?.data && modalData?.type && setModalData && (
        <ActionConfirmationModal
          {...{
            refetchTable: fetchData,
            confirmationData,
            ...modalData,
            setModalData,
          }}
        />
      )}
    </>
  );
};

export default TableWrapper;
