import { createUseStyles } from 'react-jss';
import { ThemeState } from 'store/reducers/types/theme.types';
import { panelWrapper, buttonsWrapper } from 'styles/panelsStyles';

export const useReassignTaskStyles = createUseStyles((theme: ThemeState) => ({
  usersInfoContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttonsWrapper: {
    ...buttonsWrapper,
    position: 'sticky',
    bottom: '0',
    background: '#fff',
    height: 45,
    padding: '0 15px',
    alignItems: 'flex-end',
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'calc(100% - 45px)',
    position: 'relative',
  },
  listWrapper: {
    height: '100%',
    overflow: 'auto',
  },
  stageWrapper: {
    padding: '0 15px',
  },
  resultsCounter: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '19px',
    color: theme['secondary-color'],
  },
  reassigneTaskPanelWrapper: {
    ...panelWrapper,
    padding: '15px 0',
    '& .ant-collapse-header': {
      margin: '0px 0px 10px',
    },
  },
}));
