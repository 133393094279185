import { useFormikContext } from 'formik';
import { TaskFormType } from '../../../types';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { AccountTypeOptions } from 'pages/Users/enums';
import {
  SelectUserGroupOption,
  SelectUserOption,
} from 'utils/types/selectInput.types';
import { useIntl } from 'react-intl';

export const useTaskAssigments = () => {
  const intl = useIntl();

  const { values, setFieldValue } = useFormikContext<TaskFormType>();

  const selectedUsers = values[TaskFormField.Assignees].users;
  const selectedGroups = values[TaskFormField.Assignees].groups;
  const is1TCUserSelected =
    selectedUsers[0]?.account_type === AccountTypeOptions.OneTimeCompletion;

  const disabledSelectText = intl.formatMessage({
    id: 'tasksPanel.form.oneTimeCompletionUserSelected',
    defaultMessage:
      'A one-time completion user has been assigned to this task, therefore no further users or user groups can be added.',
  });

  const checkShouldOptionBeDisabled = (
    option: SelectUserOption | SelectUserGroupOption
  ) => {
    if (!selectedGroups.length && !selectedUsers.length) {
      return false;
    }

    if (is1TCUserSelected) {
      return true;
    }

    if ('account_type' in option) {
      return option.account_type === AccountTypeOptions.OneTimeCompletion;
    }

    return false;
  };

  const handleUserDelete = (filteredUsers: SelectUserOption[]) => {
    setFieldValue(TaskFormField.Assignees, {
      users: filteredUsers,
      groups: selectedGroups,
    });
  };

  const handleGroupDelete = (filteredGroups: SelectUserGroupOption[]) => {
    setFieldValue(TaskFormField.Assignees, {
      users: selectedUsers,
      groups: filteredGroups,
    });
  };

  return {
    disabledSelectText,
    is1TCUserSelected,
    checkShouldOptionBeDisabled,
    handleUserDelete,
    handleGroupDelete,
  };
};
