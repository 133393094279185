import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EVENT_DATA_EXPAND, EVENT_DATA_LINK } from 'utils/testIds';
import isMultiline from 'utils/functions/isMultiline';
import { EventFieldType } from '../../../../../../types';
import { useEventDataExpandStyles } from './EventDataExpand.styles';
import { EventDataExpandProps } from './EventDataExpand.types';
import { EventDataJson } from './components';
import { createTestId } from 'utils/functions/createTestId';

export const EventDataExpand = ({
  isExpanded,
  items,
  fieldType,
  handleDocumentDownload,
}: EventDataExpandProps) => {
  const styles = useEventDataExpandStyles(isExpanded);
  const isDocumentGenerated =
    fieldType === undefined && items.every(item => !!item?.fileId);

  return (
    <div
      className={styles.eventDataExpandWrapper}
      data-testid={EVENT_DATA_EXPAND}
    >
      {fieldType === EventFieldType.string &&
        typeof items[0].value === 'string' &&
        (items[0].value.length > 100 || isMultiline(items[0].value)) &&
        items[0].value}

      {fieldType === EventFieldType.document && items[0].value === null && (
        <span className={styles.eventDataGray}>
          <FormattedMessage
            id='activityLog.noFiles'
            defaultMessage='No files'
          />
        </span>
      )}

      {((fieldType === EventFieldType.set && items[0].value === null) ||
        (fieldType === EventFieldType.user && items.length === 0)) && (
        <span className={styles.eventDataGray}>
          <FormattedMessage
            id='activityLog.nothingSelected'
            defaultMessage='Nothing selected'
          />
        </span>
      )}

      {fieldType === EventFieldType.json &&
        (typeof items[0].value === 'string' || items[0].value === null) && (
          <EventDataJson>{items[0].value}</EventDataJson>
        )}

      {fieldType !== EventFieldType.string &&
        fieldType !== EventFieldType.json &&
        items.map(({ id, value }) => {
          if (!isDocumentGenerated) {
            return (
              <span key={id} className={styles.eventDataExpandItem}>
                {value}
              </span>
            );
          } else {
            return (
              <a
                href={'.'}
                data-testid={createTestId(
                  EVENT_DATA_LINK,
                  value?.toString() ?? ''
                )}
                key={id}
                className={styles.eventDataDocumentDownload}
                onClick={e => {
                  e.preventDefault();
                  handleDocumentDownload(value?.toString())();
                }}
              >
                {value}
              </a>
            );
          }
        })}
    </div>
  );
};
