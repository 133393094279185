import React, { useCallback } from 'react';
import { LabeledSwitch } from 'components/lib/Switch';
import { FormattedMessage, useIntl } from 'react-intl';
import Tooltip from 'components/lib/Tooltip';
import { SyncGroupToggleProps } from './SyncGroupToggle.types';
import { useUsersAndGroupsExpandablePickerContext } from 'components/UsersAndGroupsSelection/UsersAndGroupsExpandableSelect/contexts/UsersAndGroupsExpandablePickerContext/UsersAndGroupsExpandablePickerContext';
import { useSyncGroupToggleStyles } from './SyncGroupToggle.styles';
import { isNil } from 'lodash';
import { USERS_AND_GROUPS_EXPANDED_SYNC_GROUP_TOGGLE_TESTID } from 'utils/testIds';

/**
 * Toggle that select whole group to be chosen as selected on the right hand side list.
 */
export const SyncGroupToggle = ({
  userGroup,
  isSyncGroupMembers,
}: SyncGroupToggleProps) => {
  const intl = useIntl();
  const styles = useSyncGroupToggleStyles();
  const {
    limits,
    selectedItems,
    toggleItemSelectionState,
  } = useUsersAndGroupsExpandablePickerContext();

  const onSyncMembersChange = useCallback(() => {
    toggleItemSelectionState(userGroup);
  }, [toggleItemSelectionState, userGroup]);

  const selectedGroupCount = selectedItems.groups.size;

  const countLimits = limits.selectionCountLimits;
  const isMaxLimitReached =
    !isNil(countLimits) &&
    !isNil(countLimits.maxGroups) &&
    selectedGroupCount >= countLimits.maxGroups &&
    !isSyncGroupMembers;

  return (
    <Tooltip
      overlayClassName={styles.tooltipStyle}
      overlay={
        isMaxLimitReached ? (
          <FormattedMessage
            id='misc.groupsLimitReached'
            defaultMessage='The maximum number of user groups ({ itemsLimit }) have been added.'
            values={{ itemsLimit: countLimits?.maxGroups ?? 0 }}
          />
        ) : (
          <FormattedMessage
            id='userGroups.syncGroupMembersTooltip'
            defaultMessage='Automatically adds and removes users based on the membership of the group.'
          />
        )
      }
    >
      <LabeledSwitch
        label={intl.formatMessage({
          id: 'userGroups.syncGroupMembers',
          defaultMessage: 'Sync group members',
        })}
        data-testid={USERS_AND_GROUPS_EXPANDED_SYNC_GROUP_TOGGLE_TESTID}
        checked={isSyncGroupMembers}
        onChange={onSyncMembersChange}
        disabled={isMaxLimitReached}
      />
    </Tooltip>
  );
};
