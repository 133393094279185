import { useTasksPanelContext } from '../contexts/TasksPanelContext';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import usePreviousState from 'hooks/usePreviousState';
import { useEffect } from 'react';
import { TasksPanelMode } from '../types';

export const useBackToTaskList = () => {
  const { setMode } = useTasksPanelContext();
  const {
    selectedResource: { record: { recordId = undefined } = {} } = {},
  } = useSelectedResourceContext();
  const prevRecord = usePreviousState(recordId);

  useEffect(() => {
    if (!prevRecord || prevRecord === recordId) {
      return;
    }

    setMode(TasksPanelMode.List);
  }, [prevRecord, recordId, setMode]);
};
