import React, { useMemo, useRef } from 'react';
import { Avatar } from 'components/lib/Avatar';
import useAvatarColor from 'hooks/useAvatarColor';
import { UserAvatarProps } from './types';
import useUserAvatarStyles from './styles';
import { USER_AVATAR_TESTID } from 'utils/testIds';
import Popover from 'components/lib/Popover';
import { Loader } from 'components/lib/Loader';
import useAvatar from './hooks';
import UserDetails from './components/UserDetails';
import { User } from 'utils/types/api/users.types';
import UserGroupDetails from './components/UserGroupDetails';
import { UserGroup } from 'utils/types/api/userGroups.types';
import { AccountTypeOptions } from 'pages/Users/enums';
import clsx from 'clsx';
import tryParseInt from 'utils/functions/tryParseInt';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { ScrollableParentSeekMode } from 'hooks/useScrollableParent/enums';

const UserAvatar = ({
  id,
  firstName,
  lastName,
  userGroup,
  isDeleted,
  size,
  disablePopover = false,
  getPopupContainer,
  scrollableParentId,
  closeOnElementScroll,
  accountType,
  disabled,
  isExtraLarge,
  hasFullUserDetails,
  email,
  company,
  groupNoPermissionText,
  className,
  ...rest
}: UserAvatarProps) => {
  const avatarBgColor = useAvatarColor(
    typeof id === 'string' ? Math.abs(tryParseInt(id, 1)) : Math.abs(id || 1),
    isDeleted
  );
  const isPopoverDisabled = isDeleted || disablePopover;

  const elementRef = useRef<HTMLDivElement>(null);

  const { getScrollableParent } = useScrollableParent(
    elementRef,
    scrollableParentId ? [scrollableParentId] : undefined,
    scrollableParentId
      ? ScrollableParentSeekMode.PredefinedList
      : ScrollableParentSeekMode.AnyClosest
  );

  //style props had to be memoized because of avatar changing its color on page change (couldn't find the reason why it happens)
  const memoStylesProps = useMemo(
    () => ({
      avatarBgColor,
      size,
      isUserGroup: !!userGroup,
      disablePopover: isPopoverDisabled,
      isDeleted,
      isOneTimeCompletionUser:
        accountType === AccountTypeOptions.OneTimeCompletion,
      isExtraLarge,
    }),
    [
      accountType,
      avatarBgColor,
      isDeleted,
      isExtraLarge,
      isPopoverDisabled,
      size,
      userGroup,
    ]
  );

  const styles = useUserAvatarStyles(memoStylesProps);

  const {
    handleAvatarClick,
    initials,
    isVisiblePopover,
    loading,
    details,
    noItemViewPerm,
    closePopover,
  } = useAvatar({
    firstName,
    lastName,
    groupName: userGroup,
    disablePopover: isPopoverDisabled,
    id,
    closeOnElementScroll,
    isDeleted,
    hasFullUserDetails,
    email,
    company,
  });

  return (
    // div as wrapper is necessary because Popover & Avatar components don't allow to add events onClick and onDoubleClick
    <div
      ref={elementRef}
      onClick={handleAvatarClick}
      onDoubleClick={e => e.stopPropagation()}
      className={className}
    >
      <Popover
        getPopupContainer={getPopupContainer || getScrollableParent}
        visible={isVisiblePopover}
        placement='top'
        destroyTooltipOnHide
        overlayStyle={{ maxWidth: 'min(100%, 228px)' }}
        content={
          !!userGroup ? (
            <UserGroupDetails
              groupData={details as UserGroup}
              {...{ closePopover, groupNoPermissionText }}
              noItemViewPerm={noItemViewPerm}
            />
          ) : (
            <UserDetails
              // we know here that details is of type User when usergGroup is undefined
              userData={
                ({ ...details, account_type: accountType } as unknown) as User
              }
              {...{ closePopover }}
            />
          )
        }
        overlayClassName={styles.popover}
      >
        <Loader spinning={loading} className={styles.loader}>
          <Avatar
            {...{ size, ...rest }}
            data-testid={USER_AVATAR_TESTID}
            className={clsx(styles.avatar, {
              [styles.disabledOneTimeCompletionAvatar]:
                disabled &&
                accountType === AccountTypeOptions.OneTimeCompletion,
            })}
          >
            {initials}
          </Avatar>
        </Loader>
      </Popover>
    </div>
  );
};

export default UserAvatar;
