import axios from 'axios';
import { ErrorCodes } from 'utils/types/errorResponse';

/**
 * Extracts the error code from the api call error.
 *
 * @param error Api call error from which error code will be extracted.
 * @returns Either error code if it's in the error or null if not found.
 */
export const getErrorCodeFromError = (error: unknown): ErrorCodes | null => {
  if (!axios.isAxiosError(error)) {
    return null;
  }

  if (error.response?.data.error_code) {
    return error.response?.data.error_code;
  }

  return null;
};
