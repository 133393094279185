import React, {
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useTable,
  useSortBy,
  usePagination,
  useResizeColumns,
  useColumnOrder,
  TableState,
  useBlockLayout,
} from 'react-table';
import { Input as AntInput } from 'antd';
import { Loader } from 'components/lib/Loader';
import EmptyData from 'components/EmptyData';
import { FormattedMessage } from 'react-intl';
import {
  DEFAULT_COLUMNS_WIDTHS,
  ACTIONS_COLUMN_WIDTH,
  EMPTY_TABLE_HEIGHT,
} from '../../Table.consts';
import Resizer from '../Resizer';
import useTableWrapperStyles, {
  useTableBodyStyles,
  useTableStyles,
} from '../../Table.styles';
import clsx from 'clsx';
import useEmptyTableMessage from 'hooks/useEmptyTableMessage';
import { useTableContext } from 'contexts/TableContext';
import { useTableRowContext } from 'contexts/TableRowContext';
import { useContentWrapperContext } from 'contexts/ContentWrapperContext';
import useGlobalStyle from 'hooks/useGlobalStyle';
import useAppliedFilters from 'hooks/useAppliedFilters';
import { useMenuSidebarWidth } from 'components/PageLayout/hooks';
import { typedMemo } from 'utils/types';
import { useStateReducer } from '../../hooks/useStateReducer';
import { useHasVerticalScroll } from '../../hooks/useHasVerticalScroll';
import { useScrollbars } from '../../hooks/useScrollbars';
import { useTableTotalWidthOnResize } from '../../hooks/useTableTotalWidthOnResize';
import { DraggableTableProps, RowOriginal } from './types';
import {
  CONTENT_ID,
  DUMMY_ELEM,
  TABLE_BODY_ID,
  TABLE_HEAD_ID,
  TABLE_HEAD_ROW_ID,
  TABLE_WRAPPER,
} from 'utils/elementsIds';
import SearchInput from 'components/SearchInput';
import { CustomColumnProperties } from '../../Table.types';
import orderBy from 'lodash/orderBy';
import { useMaxItemsMessage } from '../../hooks/useMaxItemsMessage';
import { useToggle } from 'hooks/useToggle';
import { TABLE_BODY_TESTID, TABLE_COLUMN_HEADER_TESTID } from 'utils/testIds';
import DraggableTablePagination from './DraggableTablePagination';
import TooltipString from 'components/TooltipString';
import { useDispatch, useSelector } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DraggableRow from './DraggableRow';
import last from 'lodash/last';
import CustomDragLayer from './CustomDragLayer';
import { reoderFields } from 'store/actions/objectClassesFieldsActions';
import { getCurrentTableCustomOffset } from 'store/selectors/generalSelectors';
import usePreviousState from 'hooks/usePreviousState';
import { SCROLL_TO_TOP_OPTIONS } from 'utils/consts';
import useEmptyTableDescription from 'hooks/useEmptyTableDescription';
import { getColumnVarName } from '../../utils';
import ResizeProvider from '../ResizeProvider';
import { useColumnStyles } from '../../hooks/useColumnStyles';
import debounce from 'lodash/debounce';
import { useDraggableTableStyles } from './styles';

export const DraggableTable: React.FC<DraggableTableProps> = ({
  isDraggingActive,
  handleDragging,
}) => {
  const dispatch = useDispatch();
  const {
    columns,
    data,
    isFetching: loading,
    sortParams,
    onChangeSort,
    initialState,
    editModeEnabled: editMode,
    onColumnResize,
    currentTableName,
    handleSearchChange,
    handleOrderChange,
    searchValue,
    newButtonLabel,
    onNewClick,
    total,
    isDraggingEnabled,
    newButtonDisabled,
    resetCustomOffset,
    hasListPermissions,
    customTableId,
  } = useTableContext();
  const customOffset = useSelector(getCurrentTableCustomOffset());
  const prevCustomOffset = usePreviousState(customOffset) || 0;

  const { density, setSelectedRow, onActionCellClick } = useTableRowContext();

  const { limitItems, maxItemsMessage } = useMaxItemsMessage();

  const emptyTableMessage = useEmptyTableMessage(currentTableName);
  const appliedFilters = useAppliedFilters();
  const sidebarWidth = useMenuSidebarWidth();
  const emptyTableDescription = useEmptyTableDescription(
    currentTableName,
    hasListPermissions,
    appliedFilters
  );

  const [initialLoad, setInitialLoad] = useState(false);

  const [temporaryState, setTemporaryState] = useState<
    TableState<object> | undefined
  >();

  const defaultColumn = useMemo(() => DEFAULT_COLUMNS_WIDTHS, []);
  const { stateReducer } = useStateReducer({
    temporaryState,
  });

  const [reorderedData, setReorderedData] = useState(data);
  const [emptyTable, setEmptyTable] = useState(false);

  const listRef = useRef<HTMLDivElement>(null);

  const [
    customLoading,
    { toggleOff: hideLoading, toggleOn: showLoading },
  ] = useToggle(false);

  const saveOrder = useCallback(
    async (index: number, id: number | undefined) => {
      const newData = [...reorderedData];
      const replacedRow = newData[index] || last(newData);

      if (!id || Number(replacedRow.id) === Number(id)) return;

      if (handleOrderChange) {
        showLoading();

        await handleOrderChange({
          id,
          movedToIndex: replacedRow.order,
        });

        dispatch(
          reoderFields({
            fromId: id.toString(),
            toOrder: replacedRow.order,
          })
        );

        handleDragging(false);
        hideLoading();
      }
    },
    [
      dispatch,
      handleOrderChange,
      hideLoading,
      reorderedData,
      showLoading,
      handleDragging,
    ]
  );

  useEffect(() => {
    const orderedData = orderBy(data, 'order');
    setReorderedData(orderedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)]);

  useEffect(() => {
    // scroll to the top after adding item to the table
    if (!!listRef.current && prevCustomOffset < customOffset)
      listRef.current.scrollTo(SCROLL_TO_TOP_OPTIONS);
  }, [customOffset, prevCustomOffset]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    totalColumnsWidth,
  } = useTable(
    {
      columns,
      data: reorderedData,
      manualSortBy: true,
      initialState: {
        ...initialState,
        sortBy: [sortParams] || [{ id: 'id', desc: true }],
      },
      defaultColumn,
      disableSortRemove: true,
      stateReducer,
    },
    useSortBy,
    usePagination,
    useResizeColumns,
    useBlockLayout,
    useColumnOrder
  );
  const { columnResizing } = state;

  const minWidth = totalColumnsWidth + ACTIONS_COLUMN_WIDTH;

  const tableBodyArea = document.getElementById(TABLE_BODY_ID + customTableId);
  const contentArea = document.getElementById(CONTENT_ID);

  const { width: tableBodyWidth } =
    tableBodyArea?.getBoundingClientRect() || {};
  const { width: pageContentWidth } =
    contentArea?.getBoundingClientRect() || {};

  const { disabledEdition } = useContentWrapperContext();

  const debounced = useCallback(
    debounce(value => {
      setEmptyTable(value);
    }, 500),
    []
  );

  useEffect(() => {
    debounced(!loading && data.length === 0);
  }, [data, loading, debounced]);

  const tableBodyRef = useRef<null | DOMRect>(null);

  const tableTotalWidth = useTableTotalWidthOnResize({
    sidebarWidth,
    totalColumnsWidth: totalColumnsWidth,
  });

  const isInitialLoading = useMemo(() => loading && !data.length, [
    data,
    loading,
  ]);

  const hasScrollY = useHasVerticalScroll();
  const classes = useTableStyles({
    density,
    emptyTable,
    hasScrollY,
  });
  const { tableBody } = useTableBodyStyles({
    tableBodyTopOffset: tableBodyRef.current?.y
      ? tableBodyRef.current.y + 46 + 14 + 40 // header height + white border + dummy item
      : 0,
    addColumnMode: disabledEdition,
    isInitialLoading,
    isNoData: !data.length,
  });
  const globalClasses = useGlobalStyle();
  const tableClasses = useTableWrapperStyles({
    ACTIONS_COLUMN_WIDTH,
    emptyTable: {
      sidebarWidth,
      totalColumnsWidth,
      ignoreTotalColumnsWidth: false,
    },
  });
  const draggableTableStyles = useDraggableTableStyles();

  useEffect(() => {
    setTemporaryState(editMode ? state : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    if (!initialLoad) {
      setInitialLoad(true);
    }
  }, [initialLoad]);

  useEffect(() => {
    const { id, desc = false } = state.sortBy[0];

    if (initialLoad) {
      onChangeSort({ id, desc });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sortBy]);
  // do not add initialLoad to dependencies, otherwise you'll break the loading currentPage number from query params

  useEffect(() => {
    if (tableBodyRef.current === null || (tableBodyRef.current && density)) {
      const bodyElement =
        window.document
          .getElementById(TABLE_WRAPPER)
          ?.getBoundingClientRect() || null;

      tableBodyRef.current = bodyElement;
    }
  }, [density, tableBodyArea]);

  const searchRef = useRef<null | AntInput>(null);

  const { onBodyScroll, onHeaderScroll } = useScrollbars({
    appliedFilters,
    customTableId,
  });

  const TABLE_COLUMN_HEADER_ACTIONS_TESTID = `${TABLE_COLUMN_HEADER_TESTID}actions`;
  const isAddNewDisabled = useMemo(
    () =>
      newButtonDisabled || (limitItems !== undefined && limitItems <= total),
    [limitItems, newButtonDisabled, total]
  );

  const onInitSearch = useCallback(() => {
    if (resetCustomOffset) dispatch(resetCustomOffset());
  }, [resetCustomOffset, dispatch]);

  const sizes = useColumnStyles(headerGroups, 29);

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    return () => {
      if (!setSelectedRow) {
        return;
      }

      dispatch(setSelectedRow(undefined));
    };
  }, [dispatch, setSelectedRow]);

  return (
    <div className={draggableTableStyles.draggableTableOuter}>
      <DraggableTablePagination
        {...{
          isAddNewDisabled,
          limitItems,
          maxItemsMessage,
          newButtonLabel,
          onNewClick,
        }}
      />

      <div
        className={classes.tableContainer}
        id={TABLE_WRAPPER}
        ref={wrapperRef}
        style={sizes}
      >
        <Loader
          spinning={loading || customLoading}
          style={{
            minHeight: isInitialLoading ? '400px' : 'auto',
            width: isInitialLoading
              ? tableTotalWidth
              : tableBodyWidth || pageContentWidth,
          }}
        >
          <div
            {...getTableProps()}
            className={clsx([
              classes.table,
              classes.tableHeaderWrapper,
              { [classes.noScrollbar]: !emptyTable },
            ])}
            id={TABLE_HEAD_ID + customTableId}
            style={{
              height: emptyTable ? EMPTY_TABLE_HEIGHT : 'auto',
            }}
            onScroll={onHeaderScroll}
          >
            <div
              className={clsx(classes.tableHeader, {
                [globalClasses.disabledTextHighlight]: !!columnResizing.isResizingColumn,
              })}
            >
              {headerGroups.map(headerGroup => {
                const { key, ...headerProps } = headerGroup.getHeaderGroupProps(
                  {
                    style: { minWidth },
                  }
                );

                return (
                  <div
                    key={key}
                    className={classes.tableHead}
                    {...headerProps}
                    id={TABLE_HEAD_ROW_ID}
                  >
                    <div
                      className={clsx([
                        tableClasses.tableCell,
                        classes.tableHeadCell,
                        tableClasses.moveableColum,
                      ])}
                      data-testid={TABLE_COLUMN_HEADER_ACTIONS_TESTID}
                    />
                    {headerGroup.headers.map(column => {
                      const {
                        key: columnKey,
                        ...columnProps
                      } = column.getHeaderProps({
                        style: {
                          minWidth: DEFAULT_COLUMNS_WIDTHS.minWidth,
                          width: `var(${getColumnVarName(column.id)})`,
                        },
                      });
                      const { searchKey } = column as CustomColumnProperties;

                      return (
                        <ResizeProvider
                          {...{ wrapperRef, onColumnResize }}
                          columnKey={column.id}
                          key={column.id}
                        >
                          {({ cellRef, initDrag }) => (
                            <div
                              className={classes.tableHeadCell}
                              {...columnProps}
                              data-testid={`${TABLE_COLUMN_HEADER_TESTID}${column.id}`}
                              ref={cellRef}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                                className={
                                  column.canSort &&
                                  !columnResizing.isResizingColumn
                                    ? clsx([
                                        classes.sortableTableHeader,
                                        {
                                          disabled: disabledEdition,
                                        },
                                      ])
                                    : ''
                                }
                              >
                                <span
                                  className={clsx(classes.ellipsis, {
                                    [classes.searchWrapper]: !!searchKey,
                                  })}
                                >
                                  <div
                                    className={clsx({
                                      [classes.labelWithSearch]: !!searchKey,
                                    })}
                                  >
                                    <TooltipString
                                      text={column.render('Header') as string}
                                    >
                                      {column.render('Header')}
                                    </TooltipString>
                                  </div>
                                  {!!searchKey && (
                                    <SearchInput
                                      {...{
                                        searchKey,
                                        handleSearchChange,
                                        onInitSearch,
                                      }}
                                      ref={searchRef}
                                      disabled={isDraggingActive}
                                    />
                                  )}
                                </span>
                              </div>
                              <Resizer
                                onMouseDown={initDrag}
                                className={clsx([
                                  'resizer',
                                  {
                                    resizing: column.isResizing,
                                  },
                                ])}
                              />
                            </div>
                          )}
                        </ResizeProvider>
                      );
                    })}
                    {onActionCellClick && (
                      <div
                        className={clsx([
                          tableClasses.tableCell,
                          classes.tableHeadCell,
                        ])}
                        style={{ minWidth: ACTIONS_COLUMN_WIDTH }}
                        data-testid={TABLE_COLUMN_HEADER_ACTIONS_TESTID}
                      >
                        <div className={tableClasses.flexAlignCenter}>
                          <span>
                            <FormattedMessage
                              id='misc.actions'
                              defaultMessage='Actions'
                            />
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            {emptyTable && (
              <div className={tableClasses.emptyTableWrapper}>
                <EmptyData
                  title={appliedFilters ? emptyTableMessage : ''}
                  description={emptyTableDescription}
                />
              </div>
            )}
          </div>
          <div
            {...getTableProps()}
            className={clsx(classes.table, classes.filledTable)}
            data-testid={TABLE_BODY_TESTID}
          >
            <DndProvider backend={HTML5Backend}>
              <CustomDragLayer />
              <div
                {...getTableBodyProps()}
                className={tableBody}
                id={TABLE_BODY_ID + customTableId}
                onScroll={onBodyScroll}
                ref={listRef}
              >
                {rows.map((row, index) => {
                  const originalRow = row.original as MappedObject<RowOriginal>;
                  prepareRow(row);

                  return (
                    <DraggableRow
                      key={`${Number(originalRow.id)}`}
                      id={Number(originalRow.id)}
                      onDragEnd={saveOrder}
                      canDrag={!searchValue?.value && !!isDraggingEnabled}
                      {...{
                        row,
                        currentTableName,
                        totalColumnsWidth,
                        index,
                        isDraggingActive,
                        handleDragging,
                      }}
                    />
                  );
                })}
                <DraggableRow
                  key={DUMMY_ELEM}
                  id={-1}
                  onDragEnd={saveOrder}
                  canDrag={false}
                  {...{
                    currentTableName,
                    totalColumnsWidth,
                    isDraggingActive,
                    index: rows.length + 1,
                    handleDragging,
                  }}
                />
              </div>
            </DndProvider>
          </div>
        </Loader>
      </div>
    </div>
  );
};

export default typedMemo(DraggableTable);
