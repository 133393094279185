import React from 'react';
import { useField } from 'formik';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';
import { UsersGroupsSelector } from '../UsersGroupsSelector';
import { TabbedUsersAndGroupsList } from '../UsersGroupsSelector/components/TabbedUsersAndGroupsList';
import { UsersGroupsListItemSelectOnly } from '../components/UsersGroupsListItemSelectOnly';
import { FUsersAndGroupsSelectProps } from './FUsersAndGroupsSelect.types';
import { FormikFormItem } from 'components/lib/Form';
import { useFUsersAndGroupsSelectStyles } from './FUsersAndGroupsSelect.styles';

export const FUsersAndGroupsSelect = ({
  name,
  isDisabled = false,
  usersAutocompleteUrl,
  groupsAutocompleteUrl,
  checkShouldOptionBeDisabled = () => false,
}: FUsersAndGroupsSelectProps) => {
  const styles = useFUsersAndGroupsSelectStyles();

  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<
    SelectUserAndGroupOption
  >(name);

  const getValidationStatus = () => {
    if (touched && error) {
      return 'error';
    }

    return undefined;
  };

  const handleChange = (newValue: SelectUserAndGroupOption) => {
    setValue(newValue);
  };

  const handleBlur = () => {
    if (touched) {
      return;
    }

    setTouched(true);
  };

  return (
    <FormikFormItem
      className={styles.formikItemWrapper}
      name={name}
      validateStatus={getValidationStatus()}
    >
      <UsersGroupsSelector
        value={value}
        disabled={isDisabled}
        onChange={handleChange}
        onBlur={handleBlur}
        popoverContent={props => (
          <TabbedUsersAndGroupsList
            {...props}
            disableMaxedOutTabs
            usersAutocompleteUrl={usersAutocompleteUrl}
            groupsAutocompleteUrl={groupsAutocompleteUrl}
            optionContent={optionProps => (
              <UsersGroupsListItemSelectOnly
                isDisabled={checkShouldOptionBeDisabled(optionProps.item)}
                {...optionProps}
              />
            )}
          />
        )}
      />
    </FormikFormItem>
  );
};
