import React, { useCallback, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useFormikContext } from 'formik';
import orderBy from 'lodash/orderBy';
import FormField from 'pages/TaskTemplates/components/FormField';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import PeopleListElement from 'pages/Records/RecordsListing/RecordAccessPanel/components/PeopleListElement';
import Popover from 'components/lib/Popover';
import { Col } from 'components/lib/Grid';
import { Alert } from 'components/lib/Alert';
import { ExclamationMarkSquare } from 'components/Icon';
import { CustomAvatarGroup } from 'components/lib/Avatar';
import { ButtonLink } from 'components/lib/Button';
import { useToggle } from 'hooks/useToggle';
import { SelectUserOption } from 'utils/types/selectInput.types';
import {
  OBJECT_CLASS_FIELD_ALLOW_GROUP_MEMBERS_SELECTION_TESTID,
  OBJECT_CLASS_FIELD_ALLOW_GROUP_SYNC_TESTID,
  OBJECT_CLASS_FIELD_USERS_AND_GROUPS_EDIT_TESTID,
  OBJECT_CLASS_FIELD_USERS_AND_GROUPS_POPOVER_TESTID,
} from 'utils/testIds';
import { UsersGroupsSelector } from 'components/UsersAndGroupsSelection/UsersGroupsSelector';
import { FormikLabeledSwitch, LabelPosition } from 'components/lib/Switch';
import GroupListElement from 'pages/UserGroups/components/Permissions/GroupListElement';
import Tooltip from 'components/lib/Tooltip';
import { useGroupsValidation, useUsersValidation } from './hooks';
import { useClassFieldPropertiesStyles } from 'styles/classFieldPropertiesStyles';
import { maxUsersCount } from '../../consts';
import { maxGroupsCount } from 'pages/ObjectClasses/components/ClassFieldFormWrapper/consts';
import { ClassFieldFormFields } from 'pages/ObjectClasses/enums';
import { ClassFieldForm } from '../../../../types';
import { TabbedUsersAndGroupsList } from 'components/UsersAndGroupsSelection/UsersGroupsSelector/components/TabbedUsersAndGroupsList';
import { UsersGroupsListItemSelectOnly } from 'components/UsersAndGroupsSelection/components/UsersGroupsListItemSelectOnly';
import { useScrollableParent } from 'hooks/useScrollableParent';
import { ScrollableParentSeekMode } from 'hooks/useScrollableParent/enums';

export const FieldUserOptions: React.FC = () => {
  const intl = useIntl();
  const styles = useClassFieldPropertiesStyles();
  const [isOpen, { toggleOff, toggle }] = useToggle();
  const usersGroupsSelectorWrapperRef = useRef<HTMLDivElement | null>(null);
  const {
    alerts: { limitUsersError, deletedUsersError, deletedUsersInfo },
    clearInfoMessage,
  } = useUsersValidation();
  const usersAlertMessage =
    limitUsersError || deletedUsersError || deletedUsersInfo;
  const isUsersAlertMessageClosable =
    !!deletedUsersInfo && !deletedUsersError && !limitUsersError;

  const {
    alerts: { limitGroupsError },
    clearInfoMessage: clearGroupsInfoMessage,
  } = useGroupsValidation();
  const groupsAlertMessage = limitGroupsError;
  const isGroupsAlertMessageClosable = !limitGroupsError;

  const {
    values: formValues,
    errors,
    setFieldValue,
    setValues,
  } = useFormikContext<ClassFieldForm>();

  const { users = [], user_groups: userGroups = [] } = formValues;

  const handleChange = useCallback(
    ({ users, groups }) => {
      if (!userGroups.length && groups.length) {
        setFieldValue(ClassFieldFormFields.AllowSync, true);
      }

      if (users.length + 1 >= maxUsersCount) toggleOff();
      setTimeout(() => {
        setFieldValue(ClassFieldFormFields.Users, users);
        setFieldValue(ClassFieldFormFields.Groups, groups);
      });
    },
    [userGroups.length, setFieldValue, toggleOff]
  );

  const shouldClearMinMaxUserValues = (
    usersCount: number,
    groupsCount: number,
    allowMembersSelection: boolean | undefined
  ) => {
    if (usersCount > 0) {
      return false;
    }

    if (allowMembersSelection && groupsCount > 0) {
      return false;
    }

    return true;
  };

  const handleDeleteUser = useCallback(
    (id: number) => {
      const { allow_members_selection: allowMembersSelection } = formValues;

      const updatedUsers = users.filter(user => user.id !== id);
      const clearMinMaxUsers = shouldClearMinMaxUserValues(
        updatedUsers.length,
        userGroups.length,
        allowMembersSelection
      );

      setValues({
        ...formValues,
        [ClassFieldFormFields.Users]: updatedUsers,
        [ClassFieldFormFields.MinUsersValues]: clearMinMaxUsers
          ? undefined
          : formValues.min_users_values,
        [ClassFieldFormFields.MaxUsersValues]: clearMinMaxUsers
          ? undefined
          : formValues.max_users_values,
      });

      if (updatedUsers.length === 0 && userGroups.length === 0) {
        toggleOff();
      }
    },
    [formValues, users, userGroups.length, setValues, toggleOff]
  );

  const handleDeleteGroup = useCallback(
    (id: number) => {
      const { allow_members_selection: allowMembersSelection } = formValues;

      const updatedGroups = userGroups.filter(group => group.id !== id);
      const clearMinMaxUsers = shouldClearMinMaxUserValues(
        updatedGroups.length,
        users.length,
        allowMembersSelection
      );

      setValues({
        ...formValues,
        [ClassFieldFormFields.Groups]: updatedGroups,
        [ClassFieldFormFields.MinUsersValues]: clearMinMaxUsers
          ? undefined
          : formValues.min_users_values,
        [ClassFieldFormFields.MaxUsersValues]: clearMinMaxUsers
          ? undefined
          : formValues.max_users_values,
        [ClassFieldFormFields.MinGroupsValues]: updatedGroups.length
          ? formValues.min_groups_values
          : undefined,
        [ClassFieldFormFields.MaxGroupsValues]: updatedGroups.length
          ? formValues.max_groups_values
          : undefined,
        [ClassFieldFormFields.AllowSync]: updatedGroups.length
          ? formValues.allow_sync
          : false,
        [ClassFieldFormFields.AllowMembersSelection]: updatedGroups.length
          ? formValues.allow_members_selection
          : false,
      });

      if (updatedGroups.length === 0 && users.length === 0) {
        toggleOff();
      }
    },
    [formValues, userGroups, users.length, setValues, toggleOff]
  );

  const { getScrollableParent } = useScrollableParent(
    usersGroupsSelectorWrapperRef,
    undefined,
    ScrollableParentSeekMode.AnyClosest
  );

  const handleOnEditClick = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      toggle();
    },
    [toggle]
  );

  const groupNoPermissionText = intl.formatMessage({
    id: 'misc.noPermissionToViewGroup',
    defaultMessage: 'No permission to view this group',
  });

  const avatarsItems = useMemo(() => {
    const userAvatars = users.map(
      ({
        first_name: firstName,
        last_name: lastName,
        id,
        account_type: accountType,
      }) => ({ firstName, lastName, id, accountType })
    );
    const groupAvatars = orderBy(userGroups, [
      ({ text }) => text.toLowerCase(),
    ]).map(({ text, id }) => {
      return {
        id,
        userGroup: text,
      };
    });
    return [...groupAvatars, ...userAvatars];
  }, [users, userGroups]);

  const popoverContent = useMemo(
    () => (
      <div
        className={styles.popoverContainer}
        data-testid={OBJECT_CLASS_FIELD_USERS_AND_GROUPS_POPOVER_TESTID}
      >
        {orderBy(userGroups, [({ text }) => text.toLowerCase()]).map(
          ({ id, text: userGroup }) => (
            <div key={id}>
              <GroupListElement
                {...{ userGroup, id, groupNoPermissionText }}
                onDelete={() => handleDeleteGroup(id)}
                truncateTooLongNames
              />
            </div>
          )
        )}

        {orderBy(users, [
          ({ first_name: name }) => name.toLowerCase(),
          ({ last_name: surname }) => surname.toLowerCase(),
        ]).map(
          ({
            first_name: firstName,
            last_name: lastName,
            id,
            account_type: accountType,
          }) => (
            <div key={id}>
              <PeopleListElement
                {...{ firstName, lastName, id, accountType }}
                onDelete={() => handleDeleteUser(id)}
              />
            </div>
          )
        )}
      </div>
    ),
    [
      styles.popoverContainer,
      handleDeleteUser,
      handleDeleteGroup,
      users,
      userGroups,
      groupNoPermissionText,
    ]
  );

  const areUsersAndGroupsMaxedOut =
    users.length >= maxUsersCount && userGroups.length >= maxGroupsCount;

  return (
    <FormField gutter={0}>
      {groupsAlertMessage && (
        <Alert
          type='error'
          message={groupsAlertMessage}
          icon={<ExclamationMarkSquare size={18} />}
          closable={isGroupsAlertMessageClosable}
          afterClose={clearGroupsInfoMessage}
          className={styles.alert}
          showIcon
        />
      )}
      {usersAlertMessage && (
        <Alert
          type='error'
          message={usersAlertMessage}
          icon={<ExclamationMarkSquare size={18} />}
          closable={isUsersAlertMessageClosable}
          afterClose={clearInfoMessage}
          className={styles.alert}
          showIcon
        />
      )}
      <Col span={24}>
        <FormLabel required>
          <FormattedMessage
            id='objectClasses.fields.usersAndGroupsSelected'
            defaultMessage='Users and groups that can be selected'
          />
        </FormLabel>
        <div
          ref={usersGroupsSelectorWrapperRef}
          className={styles.fieldUserOptionsWrapper}
        >
          <Tooltip
            title={
              areUsersAndGroupsMaxedOut
                ? intl.formatMessage({
                    id: 'misc.maxOptionsAdded',
                    defaultMessage: 'Maximum options have been added',
                  })
                : ''
            }
          >
            <UsersGroupsSelector
              value={{ users: users as SelectUserOption[], groups: userGroups }}
              popoverContent={props => (
                <TabbedUsersAndGroupsList
                  {...props}
                  disableMaxedOutTabs
                  optionContent={optionProps => (
                    <UsersGroupsListItemSelectOnly {...optionProps} />
                  )}
                />
              )}
              onChange={handleChange}
              getPopupContainer={getScrollableParent}
              disabled={areUsersAndGroupsMaxedOut}
              maxSelectedGroups={maxGroupsCount}
              maxSelectedUsers={maxUsersCount}
            />
          </Tooltip>
        </div>
      </Col>
      <CustomAvatarGroup
        items={avatarsItems}
        disablePopover={isOpen}
        customMoreText='···'
        groupNoPermissionText={groupNoPermissionText}
      />
      <Popover
        content={popoverContent}
        trigger='contextMenu'
        visible={isOpen}
        onVisibleChange={(value: boolean) => !value && toggleOff()}
        getPopupContainer={getScrollableParent}
        overlayClassName={styles.popoverWrapper}
      >
        {(!!users.length || !!userGroups.length) && (
          <div className={styles.avatarWrapper}>
            <ButtonLink
              onClick={handleOnEditClick}
              data-testid={OBJECT_CLASS_FIELD_USERS_AND_GROUPS_EDIT_TESTID}
            >
              <FormattedMessage id='misc.edit' defaultMessage='Edit' />
            </ButtonLink>
          </div>
        )}
      </Popover>
      {!!userGroups.length && (
        <Col span={24}>
          <FormLabel required className={styles.formLabelNoGap}>
            <FormattedMessage
              id='objectClasses.fields.groupsInFieldConfiguration'
              defaultMessage='Groups in field configuration'
            />
          </FormLabel>
          <div className={styles.description}>
            <FormattedMessage
              id='objectClasses.fields.configureEndUserGroupInteraction'
              defaultMessage='Configure how end-users can interact with selected groups.'
            />
          </div>
          <div className={styles.switchWrapper}>
            <FormikLabeledSwitch
              name={ClassFieldFormFields.AllowSync}
              position={LabelPosition.right}
              label={intl.formatMessage({
                id: 'objectClasses.fields.allowSyncingGroupMembers',
                defaultMessage: 'Allow syncing group members',
              })}
              dataTestId={OBJECT_CLASS_FIELD_ALLOW_GROUP_SYNC_TESTID}
            />
          </div>
          <div className={styles.switchWrapper}>
            <FormikLabeledSwitch
              name={ClassFieldFormFields.AllowMembersSelection}
              label={intl.formatMessage({
                id: 'objectClasses.fields.allowSyncingIndividualMembers',
                defaultMessage: 'Allow selecting individual members',
              })}
              position={LabelPosition.right}
              dataTestId={
                OBJECT_CLASS_FIELD_ALLOW_GROUP_MEMBERS_SELECTION_TESTID
              }
            />
          </div>
          {errors[ClassFieldFormFields.AllowSync] && (
            <div className={styles.error}>
              <FormattedMessage
                id='objectClasses.fields.atLeastOneOptionMustBeEnabled'
                defaultMessage='At least one option must be enabled'
              />
            </div>
          )}
        </Col>
      )}
    </FormField>
  );
};
