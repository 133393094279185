import useData from 'hooks/useData';
import { TaskGroupAssigneeOptions } from '../types';
import { TASK_GROUP_ASSIGNEES } from 'utils/endpoints';
import { generatePath } from 'react-router-dom';

export const useTaskGroupAssigneeOptions = () => {
  const [
    options,
    { loading: areOptionsLoading, error: optionsError },
  ] = useData<TaskGroupAssigneeOptions>(
    generatePath(TASK_GROUP_ASSIGNEES, { id: 0 }),
    {
      requestType: 'options',
    }
  );

  return { options, areOptionsLoading, optionsError };
};
