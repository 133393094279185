import useData from 'hooks/useData';
import { TASKS_LIST } from 'utils/endpoints';
import { TaskOptions } from '../../types';

export const useTaskOptions = () => {
  const [
    options,
    { loading: areOptionsLoading, error: optionsError },
  ] = useData<TaskOptions>(TASKS_LIST, {
    requestType: 'options',
  });

  return {
    options,
    areOptionsLoading,
    optionsError,
  };
};
