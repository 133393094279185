import { FIELD_PREFIX } from 'utils/consts';
import { TPredicateTypes } from 'utils/types/predicates.types';

/**
 * Parses provided filter params to base query. May contain placeholders for query arguments that
 * should later be replaced with corresponding filter values.
 *
 * @param filterKey - Filter key.
 * @param filterType - Filter type.
 * @param predicateQuery - Selected filter predicate query e.g. "__istartswith={value}".
 *
 * @returns Base query for the provided filter.
 */
export const getFilterQueryBase = (
  filterKey: string,
  filterType: TPredicateTypes | '',
  predicateQuery: string
) => {
  if (filterType === 'user_and_group') {
    return `${filterKey}__{prefix}${predicateQuery}`;
  }

  if (filterType === 'user' && filterKey.includes(FIELD_PREFIX)) {
    return `${filterKey}__users${predicateQuery}`;
  }

  return `${filterKey}${predicateQuery}`;
};
