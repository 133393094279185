import { createUseStyles } from 'react-jss';
import {
  headerWrapper,
  getResultsInfo,
  getPanelTitle,
} from 'styles/panelsStyles';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useReassignTaskHeaderStyles = createUseStyles(
  (theme: ThemeState) => ({
    headerWrapper: {
      ...headerWrapper,
      marginBottom: 0,
      padding: '0 15px',
    },
    panelTitle: getPanelTitle(theme),
    resultsInfo: getResultsInfo(theme),
    nameWrapper: {
      ...headerWrapper,
      padding: '0 15px',
    },
    taskName: {
      fontWeight: 700,
      fontSize: 14,
      lineHeight: '19px',
    },
  })
);
