import { ApiUserAvatar, UserCreatedBy } from './users.types';

export type DocumentTemplate = {
  id: string;
  completed_at: Date;
  created_at: Date;
  modified_at: Date;
  modified_by: ApiUserAvatar;
  complete_by: ApiUserAvatar;
  description?: string;
  name: string;
  allow_on_demand_generation?: boolean;
  template_file_name: string;
  template_file: number;
  _meta: {
    labels: {
      files: MappedObject<MappedObject<string>, string | number>;
    };
  };
};

export interface DocumentTemplatePanel {
  id: string;
  generated_document: GeneratedDocument | null;
}

export enum DocumentStatus {
  Processing = 'processing',
  Canceled = 'canceled',
  Completed = 'completed',
  FieldUpdated = 'field_updated',
  FieldNotUpdated = 'field_not_updated',
  Failed = 'failed',
}

export interface GeneratedDocument {
  status: DocumentStatus;
  error_message: string;
  document: number | null;
  generated_at: string;
  generated_by: UserCreatedBy;
  _meta: {
    labels: {
      document: {
        name: string;
        url: string;
        size: number;
        type: string;
      };
    };
  };
}
