import { FilterValue } from 'store/actions/filtersActions';
import { Predicate } from 'utils/types/predicates.types';
import {
  getFilterQueryBase,
  parseFilterValue,
  parseUserAndGroupFilterType,
  predicateValuesMapFunction,
} from './utils';
import { SelectUserAndGroupOption } from 'utils/types/selectInput.types';

/**
 * Parses provided filter condition to query param that can be later on added to query string.
 *
 * @param filter - Filter condition to parse.
 * @param predicate - Selected filter predicate.
 *
 * @returns Valid query param based on provided filter condition.
 */
export const parseFilterToQueryParam = (
  filter: FilterValue,
  predicate: Predicate
) => {
  const filterValues = filter.value.predicateValues;
  const queryArgs = predicate.args;
  const queryBase = getFilterQueryBase(
    filter.column,
    filter.type,
    predicate.query
  );

  if (filter.type === 'user_and_group') {
    return parseUserAndGroupFilterType(
      filterValues['value'] as SelectUserAndGroupOption | undefined,
      queryBase,
      queryArgs
    );
  }

  return queryArgs.reduce((query, arg) => {
    let escapedValues;

    if (Array.isArray(filterValues[arg])) {
      escapedValues = (filterValues[arg] as []).map(predicateValuesMapFunction);
    } else {
      escapedValues = filterValues[arg];
    }

    const predicateValue = parseFilterValue(escapedValues);

    return query.replace(
      `{${arg}}`,
      encodeURIComponent(predicateValue as string)
    );
  }, queryBase);
};
