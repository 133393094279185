import ToastType from './Enums/ToastType';

export const ACTIONS_MENU_ITEM_TESTID = 'actions-menu-item';
export const ADD_COLUMNS_POPOVER_TESTID = 'add-columns-popover';
export const ADD_COLUMNS_POPOVER_TITLE_TESTID = 'add-columns-popover-title';
export const COLUMN_TESTID = 'column_';
export const CANCEL_ADDING_COLUMNS_TESTID = 'cancel-adding-columns-button';
export const ADD_COLUMNS_TESTID = 'add-columns-button';
export const ADD_COLUMN_PIN_TESTID = 'add-column-pin-';
export const AUTOCOMPLETE_SELECT_TESTID = 'autocomplete-select-';
export const INFORMATION_WRAPPER_TESTID = 'information-wrapper';
export const INFORMATION_TITLE_TESTID = 'information-title';
export const INFORMATION_SUBTITLE_TESTID = 'information-subtitle';
export const INFORMATION_DESCRIPTION_TESTID = 'information-description';
export const BACK_BUTTON_TESTID = 'back-button';
export const BREADCRUBMS_LIST_TESTID = 'breadcrumbs-list';
export const HIDDEN_BREADCRUMBS_LIST_TESTID = 'hidden-breadcrumbs-list';
export const BREADCRUBMS_ITEM_TESTID = 'breadcrumb-item';
export const CARD_WRAPPER_TESTID = 'card-wrapper';
export const VIEW_OPTION_BUTTON_TESTID = 'view-option-button';
export const SUMMARY_PANEL_FIELD_TESTID = 'summary-panel-field';
export const RICH_TEXT_EDITOR = 'rich-text-editor';
export const ENABLE_SWITCH_TESTID = 'enable-switch';
export const EXIT_PREVIEW_TESTID = 'exit-preview';
// modal
export const MODAL_TESTID = 'modal';
export const MODAL_CONFIRM_BUTTON_TESTID = 'modal-confirm-button';
export const MODAL_HEADING_TESTID = 'modal-heading';
export const MODAL_TITLE_TESTID = 'modal-title';
export const MODAL_SUBTITLE_TESTID = 'modal-subtitle';
export const MODAL_CANCEL_BUTTON_TESTID = 'modal-cancel-button';
export const MODAL_DISCARD_BUTTON_TESTID = 'modal-discard-button';
export const MODAL_COLUMN_TO_REMOVE_TESTID = 'modal-column-to-remove';
export const CARD_MODAL_TESTID = 'card-modal';
export const CARD_MODAL_SAVE_TESTID = 'card-modal-save';
export const CARD_MODAL_CANCEL_TESTID = 'card-modal-cancel';

// filters
export const FILTER_COLUMN_SELECT_TESTID = 'filter-column-select';
export const FILTER_PREDICATE_SELECT_TESTID = 'filter-predicate-select';
export const FILTER_INPUT_TESTID = 'filter-input';

export const ERROR_COMPONENT_TESTID = 'error-component';
export const ADD_CONDITION_BUTTON_TESTID = 'add-condition-button';
export const REMOVE_ALL_FILTERS_TESTID = 'remove-all-filters-button';
export const APPLY_FILTERS_TESTID = 'apply-filters-button';
export const REMOVE_SINGLE_FILTER_TESTID = 'remove-single-filter';
export const SINGLE_FILTER_CONTAINER_TESTID = 'single-filter-container';
export const OPEN_FILTER_EDITOR_TESTID = 'open-filter-editor-button';
export const FILTER_INDICATOR_TESTID = 'filter-indicator';
export const FILTER_INDICATOR_CLEAR_FILTERS_TESTID =
  'filter-indicator-clear-filters';
export const SELECT_PREDICATE_TESTID = 'select-predicate-';
export const FORM_LABEL_TESTID = 'form-label-';
export const FORM_BUILDER_REMOVE_ITEM_TESTID = 'form-builder-remove-item';
export const FORM_BUILDER_SECTION_TESTID = 'form-builder-section';
export const FORM_BUILDER_CONTENT_TESTID = 'form-builder-content';
export const FORM_BUILDER_EMPTY_TESTID = 'form-builder-empty';
export const DRAGGABLE_ITEM_TESTID = 'draggable-item';
export const DRAGGABLE_ITEM_CLONE_TESTID = 'draggable-item-clone';
export const FORM_BUILDER_SECTION_ITEM_TESTID = 'form-builder-section-item';
export const FORM_BUILDER_EMPTY_SECTION_WRAPPER_TESTID =
  'form-builder-empty-section-wrapper';
export const FORM_BUILDER_EMPTY_SECTION_TITLE_TESTID =
  'form-builder-empty-section-title';
export const FORM_BUILDER_EMPTY_SECTION_DESC_TESTID =
  'form-builder-empty-section-desc';
export const FORM_BUILDER_EMPTY_OPTIONS_LIST_LABEL_TESTID =
  'form-builder-empty-options-list-label';
export const FORM_BUILDER_CUSTOM_HEADER_TESTID = 'form-builder-custom-header';
export const FORM_BUILDER_CUSTOM_HEADER_ARROW_TESTID =
  'form-builder-custom-haeder-arrow';
export const FORM_BUILDER_REMOVE_SECTION_TESTID = 'form-builder-remove-section';
export const FORM_BUILDER_FORM_NAME_INPUT_TESTID =
  'form-builder-form-name-input';
export const FORM_BUILDER_ENABLE_REDIRECT_SWITCH_TESTID =
  'form-builder-enable-redirect-switch';
export const FORM_BUILDER_REDIRECT_URL_INPUT_TESTID =
  'form-builder-redirect-url-input';
export const UNSUPPORTED_SCHEMA_TEXT_TESTID = 'unsupported-schema-text';
export const UNSUPPORTED_SCHEMA_BUTTON_TESTID = 'unsupported-schema-button';
export const UNSUPPORTED_SCHEMA_CLEAR_BUTTON_TESTID =
  'unsupported-schema-clear-button';
export const FORM_BUILDER_HEADER_CLOSE_TESTID =
  'form-builder-header-close-button';
export const FORM_BUILDER_HEADER_REVERT_FORM_TESTID =
  'form-builder-header-revert-form';
export const FORM_BUILDER_HEADER_SUBMIT_TESTID = 'form-builder-header-submit';
export const FORM_HEADER_HEADING_TESTID = 'form-header-heading';
export const FORM_HEADER_DETAILS_TESTID = 'form-header-details-tooltip';
export const FORM_HEADER_EDIT_WRAPPER_TESTID = 'form-header-edit-wrapper';
export const FORM_HEADER_WRAPPER_TESTID = 'form-header-wrapper';
export const FORM_HEADER_CANCEL_TESTID = 'form-header-cancel';
export const FORM_HEADER_SUBMIT_TESTID = 'form-header-submit';
export const OPEN_FORM_PREVIEW_TESTID = 'open-form-preview';
export const FORM_PREVIEW_TESTID = 'form-preview';
export const FORM_DRAWER_TESTID = 'form-drawer';
export const SELECT_OPTION_TESTID = 'select-option';
export const DISABLED_PASSWORD_INPUT_TESTID = 'disabled-password-input-';
export const PASSWORD_INPUT_TESTID = 'password-input-';
export const FORM_SELECT_TESTID = 'form-select-';
export const TEXTAREA_WRAPPER_TESTID = 'textarea-wrapper';
export const TEXTAREA_TESTID = 'textarea-';
export const ANT_TEXTAREA_TESTID = 'ant-textarea';
export const DISABLED_TEXT_INPUT_TESTID = 'disabled-text-input-';
export const TEXT_INPUT_TESTID = 'text-input-';
export const NUMBER_INPUT_TESTID = 'number-input-';
export const PHONE_INPUT_TESTID = 'phone-input-';
export const SINGLE_LINE_INPUT_TESTID = 'single-line-input-';
export const JSON_INPUT_TESTID = 'json-input-';
export const EMAIL_INPUT_TESTID = 'email-';
export const DRAWER_TESTID = 'drawer';
export const ADD_NEW_TAB_BUTTON_TESTID = 'add-new-tab-button-';
export const CONTENT_TESTID = 'content';
export const ADD_TAB_BUTTON_TESTID = 'add-tab-button';
export const OPEN_EXTERNAL_WINDOW_TESTID = 'open-external-window-button';
export const SECTION_HEADER_ARROW_TESTID = 'section-haeder-arrow';
export const SIDE_PANEL_TAB_TESTID = 'side-panel-tab-';
export const SIDE_PANEL_CLOSE_TAB_ICON_TESTID = 'side-panel-close-tab-icon';
export const SIDE_PANEL_TESTID = 'side-panel';
export const ANT_SELECT_TESTID = 'ant-select';
export const ANT_MULTI_SELECT_TESTID = 'ant-multi-select';
export const SWITCH_BUTTON_TESTID = 'switch-button-';
export const TABS_TESTID = 'tabs';
export const TRANSFER_CHECKBOX_TESTID = 'transfer-checkbox';
export const TRANSFER_CHECKBOX_TITLE_TESTID = 'transfer-checkbox-title';
export const TRANSFER_CHECKBOX_WRAPPER_TESTID = 'transfer-checkbox-wrapper';
export const NO_DATA_TITLE_TESTID = 'no-data-title';
export const NO_DATA_SUBTITLE_TESTID = 'no-data-subtitle';
export const PAGE_HEADER_HEADING_TESTID = 'page-header-heading';
export const SIDEBAR_MENU_TESTID = 'sidebar-menu';
export const SIDEBAR_MENU_WRAPPER_TESTID = 'sidebar-menu-wrapper';
export const SIMPLE_LAYOUT_SIDEBAR_TESTID = 'simple-sidebar-menu-wrapper';
export const ALX_LOGO_WRAPPER_TESTID = 'alx-logo-wrapper';
export const ALX_LOGO_SIMPLE_LAYOUT_TESTID = 'simple-layout-alx-logo';
export const SIDEBAR_MENU_PIN_TESTID = 'sidebar-menu-pin';
export const MY_ACCOUNT_TESTID = 'my-account';
export const LOGOUT_BUTTON_TESTID = 'logout-button';
export const PASSWORD_RULE_SATISFIED_TESTID = 'password-rule-satisfied-';
export const PASSWORD_RULE_NO_SATISFIED_TESTID = 'password-rule-not-satisfied-';
export const REQUEST_SENT_CARD_TITLE_TESTID = 'request-sent-card-title';
export const REQUEST_SENT_CARD_SUBTITLE_TESTID = 'request-sent-card-subtitle';
export const TABLE_SORTABLE_COLUMN_HEADER_TESTID =
  'table-sortable-column-header-';
export const TABLE_COLUMN_HEADER_TESTID = 'table-column-header-';
export const TABLE_SORTABLE_TABLE_HEADER_TESTID =
  'table-sortable-table-header-';
export const REMOVE_COLUMN_TESTID = 'remove-column-button';
export const TABLE_SORTABLE_RESIZER_TESTID = 'table-sortable-resizer-';
export const TABLE_BULK_SELECTION_HEADER_TESTID = 'table-bulk-selection-header';
export const TABLE_BULK_SELECTION_HEADER_CHECKBOX_TESTID =
  'table-bulk-selection-header-checkbox';
export const TABLE_BULK_SELECTION_ACTION_ADD_BUTTON_TESTID =
  'table-bulk-selection-action-add-button';
export const TABLE_BULK_SELECTION_ACTION_REMOVE_BUTTON_TESTID =
  'table-bulk-selection-action-remove-button';
export const TABLE_BULK_SELECTION_COUNT_MESSAGE_TESTID =
  'table-bulk-selection-count-message';
export const TABLE_BULK_SELECTION_LIMIT_ALERT_TESTID =
  'table-bulk-selection-limit-alert';
export const TABLE_BULK_SELECTION_ROW_CHECKBOX_WRAPPER_TESTID =
  'table-bulk-selection-row-checkbox-wrapper';
export const TABLE_BULK_SELECTION_ROW_CHECKBOX_TESTID =
  'table-bulk-selection-row-checkbox';
export const TABLE_EMPTY_COLUMN_TESTID = 'table-empty-column';
export const TABLE_BODY_TESTID = 'table-body';
export const TABLE_PAGINATION_WRAPPER_TESTID = 'table-pagination-wrapper';
export const TABLE_PAGINATION_GO_TO_FIRST_TESTID =
  'table-pagination-go-to-first';
export const TABLE_PAGINATION_GO_TO_PREVIOUS_TESTID =
  'table-pagination-go-to-previous';
export const TABLE_PAGINATION_GO_TO_NEXT_TESTID = 'table-pagination-go-to-next';
export const TABLE_PAGINATION_GO_TO_LAST_TESTID = 'table-pagination-go-to-last';
export const TABLE_PAGINATION_CURRENT_PAGE_TESTID =
  'table-pagination-current-page';
export const TABLE_PAGINATION_COUNT_OF_PAGES_TESTID =
  'table-pagination-count-of-pages';
export const TABLE_PAGINATION_SELECT_PER_PAGE_TESTID =
  'table-pagination-select-per-page';
export const TABLE_PAGINATION_SELECT_OPTION_TESTID =
  'table-pagination-select-option-';
export const TABLE_PAGINATION_SELECT_OPTION_ALL_TESTID =
  'table-pagination-select-option-all';
export const TABLE_SETTINGS_TESTID = 'table-settings';
export const TABLE_ACTIONS_BUTTONS_TESTID = 'table-actions-buttons';
export const TABLE_ADD_NEW_ITEM_TOOLTIP_TESTID = 'table-add-new-item-tooltip';
export const TABLE_ADD_NEW_ITEM_TESTID = 'table-add-new-item';
export const COLUMN_CONFIGURATION_BUTTON_TESTID = 'column-configuration-button';
export const TABLE_COLUMN_CONFIGURATION_BUTTONS_TESTID =
  'table-column-configuration-buttons';
export const SAVE_COLUMNS_CHANGES_TESTID = 'save-columns-changes-button';
export const RESET_COLUMNS_TO_DEFAULT_TESTID =
  'reset-columns-to-default-button';
export const CANCEL_EDIT_MODE_TESTID = 'cancel-edit-mode-button';
export const TABLE_ROW_ITEM_ID_TESTID = 'table-row-item-id-';
export const TABLE_CELL_COLUMN_NAME_TESTID = 'table-cell-column-name-';
export const TABLE_ROW_ACTION_BUTTON_TESTID = 'table-row-action-button';
export const DRAGGABLE_TABLE_NEW_BUTTON_TESTID = 'draggable-table-new-button';
export const TRANSFER_WRAPPER_TESTID = 'transfer-wrapper';
export const SEARCH_LEFT_COLUMN_TESTID = 'search-left-column';
export const SEARCH_INPUT_TESTID = 'search-input';
export const BUTTON_MOVE_UP_TESTID = 'button-move-up';
export const BUTTON_MOVE_DOWN_TESTID = 'button-move-down';
export const VIEW_TESTID = 'view';
export const TOP_MENU_DROPDOWN_ACCOUNT_TESTID = 'top-menu-dropdown-account';
export const USER_NAME_TESTID = 'user-name';
export const USER_AVATAR_TESTID = 'user-avatar';
export const SHOW_PASSWORD_TESTID = 'show-password';
export const BUTTON_SUBMIT_TESTID = 'button-submit';
export const ACTIVATION_ACCOUNT_TITLE_TESTID = 'activation-account-title';
export const ACTIVATION_ACCOUNT_SUBTITLE_TESTID = 'activation-account-subtitle';
export const EXPIRED_LINK_TITLE_TESTID = 'expired-link-title';
export const EXPIRED_LINK_SUBTITLE_TESTID = 'expired-link-subtitle';
export const EXPIRED_LINK_NEWLINK_TESTID = 'expired-link-newlink';
export const FORGOT_PASSWORD_TITLE_TESTID = 'forgot-password-title';
export const FORGOT_PASSWORD_SUBTITLE_TESTID = 'forgot-password-subtitle';
export const LOGIN_TITLE_TESTID = 'login-title';
export const LOGIN_EMAIL_TESTID = 'login-email';
export const LOGIN_PASSWORD_TESTID = 'login-password';
export const LOGIN_GO_TO_RESET_TESTID = 'login-go-to-reset';
export const LOGIN_FOOTER_TEXT_TESTID = 'login-footer-text';
export const LANGUAGE_TESTID = 'language-';
export const RESET_PREFERENCES_TESTID = 'reset-preferences';
export const BUTTON_CANCEL_FIELD_FORM_TESTID = 'button-cancel-field-form';
export const BUTTON_SUBMIT_FIELD_FORM_TESTID = 'button-submit-field-form';
export const BUTTON_CANCEL_TESTID = 'button-cancel';
export const BUTTON_CLOSE_TESTID = 'button-close';
export const VIEW_USAGE_SECTION_TESTID = 'view-usage-section-';
export const VIEW_USAGE_TITLE_TESTID = 'view-usage-title';
export const VIEW_USAGE_LIST_ITEM_TESTID = 'view-usage-list-item';
export const VIEW_USAGE_DESC_TESTID = 'view-usage-desc';
export const VIEW_USAGE_DESC_TO_USAGE_TESTID = 'view-usage-desc-no-usage';
export const RESET_PASSWORD_TITLE_TESTID = 'reset-password-title';
export const SWITCH_ROW_LABEL_TESTID = 'switch-row-label-';
export const CANCEL_FORM_BUILDER_TESTID = 'cancel-form-builder';
export const SAVE_FORM_BUILDER_TESTID = 'save-form-builder';
export const CREATE_RESPONSE_FORM_TESTID = 'create-response-form';
export const DELETE_FORM_ICON_TESTID = 'delete-form-icon';
export const EDIT_REPONSE_FORM_TESTID = 'edit-response-form';
export const SORT_ASC_TESTID = 'sort_asc';
export const SORT_DESC_TESTID = 'sort_desc';
export const LINK_TESTID = 'link';
export const REMOVE_CONDITION_TESTID = 'remove-condition';
export const REMOVE_REMINDER_TESTID = 'remove-reminder';
export const ADD_REMINDER_TESTID = 'add-reminder';
export const CONDITION_WRAPPER_TESTID = 'condition-wrapper';
export const ADD_CONDITION_TESTID = 'add-condition';
export const LIFECYCLE_RADIO_TESTID = 'lifecycle-radio';
export const LIFECYCLE_RADIO_LABEL_TESTID = 'lifecycle-radio-label';
export const RADIO_INPUT_TESTID = 'radio-input-';
export const NEXT_ARROW_TESTID = 'next-arrow';
export const PREV_ARROW_TESTID = 'prev-arrow';
export const ADD_CONDITIONAL_VISIBILITY_TESTID = 'add-conditional-visibility';
export const REMOVE_CONDITIONAL_VISIBILITY_TESTID =
  'remove-conditional-visibility';
export const EXPECTED_VALUE_INPUT_TESTID = 'expected-value-input';
export const COMPONENT_OPTIONS_TESTID = 'component-options';
export const PREDICATE_OPTIONS_TESTID = 'predicate-options';
export const SECTION_HEADER_TESTID = 'section-header';

export const FORM_BUILDER_SECTION_TITLE_TESTID = 'fb-section-title';
export const FORM_BUILDER_SECTION_ALIAS_TESTID = 'fb-section-alias';
export const FORM_BUILDER_SECTION_SHOW_HEADER_SWITCH_TESTID =
  'fb-section-show-header-switch';
export const FORM_BUILDER_SECTION_COLUMNS_TESTID = 'fb-section-columns';
export const FORM_BUILDER_SECTION_COLUMN_RATIOS_TESTID =
  'fb-section-column-ratios';
export const FORM_BUILDER_SECTION_SPACING_AROUND_TESTID =
  'fb-section-spacing-around';
export const FORM_BUILDER_SECTION_SPACING_WITHIN_TESTID =
  'fb-section-spacing-within';

export const FORM_BUILDER_HTML_ELEMENT_TESTID = 'fb-html-element';
export const FORM_BUILDER_INPUT_TYPE_PARAMETER_TESTID = 'fb-input-param-type';
export const FORM_BUILDER_CHBOX_DEFAULT_PARAMETER_TESTID =
  'fb-chbox-param-default';
export const FORM_BUILDER_DESCRIPTION_PARAMETER_TESTID =
  'fb-input-param-description';
export const FORM_BUILDER_NUMBER_PRECISION_PARAMETER_TESTID =
  'fb-input-number-param-precision';
export const FORM_BUILDER_NUMBER_DEFAULT_PARAMETER_TESTID =
  'fb-input-number-param-default';
export const FORM_BUILDER_INPUT_LABEL_PARAMETER_TESTID = 'fb-input-param-label';
export const FORM_BUILDER_INPUT_ALIAS_PARAMETER_TESTID = 'fb-input-param-alias';
export const FORM_BUILDER_INPUT_REQUIRED_PARAMETER_TESTID =
  'fb-input-param-required';
export const FORM_BUILDER_INPUT_READ_ONLY_PARAMETER_TESTID =
  'fb-input-param-read-only';

export const FORM_BUILDER_MULTI_SELECT_DEFAULT_PARAMETER_TESTID =
  'fb-multi-select-param-default';
export const FORM_BUILDER_SINGLE_SELECT_DEFAULT_PARAMETER_TESTID =
  'fb-single-select-param-default';
export const FORM_BUILDER_ITEMS_VALIDATION_TESTID = 'fb-items-validation';

export const FORM_BUILDER_NUMERIC_MIN_VALIDATION_TESTID =
  'fb-numeric-min-validation';
export const FORM_BUILDER_NUMERIC_MAX_VALIDATION_TESTID =
  'fb-numeric-max-validation';
export const FORM_BUILDER_TEXT_VALIDATION_TESTID = 'fb-text-validation';
export const FORM_BUILDER_DATE_VALIDATION_TESTID = 'fb-date-validation';
export const FORM_BUILDER_TEXT_DEFAULT_PARAMETER_TESTID =
  'fb-input-text-param-default';

export const CONDITIONAL_VISIBILITY_CANCEL_TESTID = 'cv-cancel-btn';
export const CONDITIONAL_VISIBILITY_APPLY_TESTID = 'cv-apply-btn';

//record access
export const RECORD_ACCESS_SEARCH = 'record-access-search';
export const RECORD_ACCESS_PERMISSION_SET_SELECT =
  'record-access-permission-set-select';
export const RECORD_ACCESS_VIEW_PERMISSION_SETS =
  'record-access-view-permission-sets';
export const RECORD_ACCESS_VIEW_PERMISSION_SETS_BUTTON =
  'record-access-view-permission-sets-button';
export const RECORD_ACCESS_REMOVE_PERMISSION_SETS_BUTTON =
  'record-access-remove-permission-sets-button';
export const RECORD_ACCESS_IDENTIFIER = 'record-access-identifier';

//object classes
export const OBJECT_CLASS_LIST = 'object-class-list';
export const OBJECT_CLASS_FIELD_DEFAULT_VALUE =
  'object-class-field-default-value';
export const OBJECT_CLASS_FIELD_OPTIONS = 'object-class-field-options';
export const OBJECT_CLASS_FIELD_MAX_LENGTH = 'object-class-field-max-length';
export const OBJECT_CLASS_FIELD_MIN_VALUE = 'object-class-field-min-value';
export const OBJECT_CLASS_FIELD_MAX_VALUE = 'object-class-field-max-value';
export const OBJECT_CLASS_FIELD_MIN_OPTIONS = 'object-class-field-min-options';
export const OBJECT_CLASS_FIELD_MAX_OPTIONS = 'object-class-field-max-options';
export const OBJECT_CLASS_FIELD_MIN_USERS_OPTIONS =
  'object-class-field-min-users-options';
export const OBJECT_CLASS_FIELD_MAX_USERS_OPTIONS =
  'object-class-field-max-users-options';
export const OBJECT_CLASS_FIELD_MIN_GROUPS_OPTIONS =
  'object-class-field-mingroups-options';
export const OBJECT_CLASS_FIELD_MAX_GROUPS_OPTIONS =
  'object-class-field-max-groups-options';
export const OBJECT_CLASS_FIELD_USERS_AND_GROUPS_EDIT_TESTID =
  'object-class-field-users-and-groups-edit';
export const OBJECT_CLASS_FIELD_USERS_AND_GROUPS_POPOVER_TESTID =
  'object-class-field-users-and-groups-popover';
export const OBJECT_CLASS_FIELD_ALLOW_GROUP_SYNC_TESTID =
  'object-class-field-allow-group-sync';
export const OBJECT_CLASS_FIELD_ALLOW_GROUP_MEMBERS_SELECTION_TESTID =
  'object-class-field-allow-group-members-selection';
export const OBJECT_CLASS_FIELD_MAX_FILES = 'number-input-max_num_of_files';
export const OBJECT_CLASS_NAME = 'object-class-name';
export const OBJECT_CLASS_DESCRIPTION = 'object-class-description';
export const OBJECT_CLASS_FIELD_TYPE = 'object-class-field-type';
export const OBJECT_CLASS_FIELD_IDENTIFIER = 'object-class-field-identifier';
export const OBJECT_CLASS_FIELD_IDENTIFIER_INFO =
  'object-class-field-identifier-info';
export const OBJECT_CLASS_FIELD_UNIQUE = 'object-class-field-unique';
export const OBJECT_CLASS_FIELD_UNIQUE_ERROR =
  'object-class-field-unique-error';
export const OBJECT_CLASS_DISPLAY_TYPE_SELECT =
  'object-class-display-type-select';
export const OBJECT_CLASS_DISPLAY_REVERT = 'object-class-display-revert';
export const OBJECT_CLASS_DISPLAY_CANCEL = 'object-class-display-cancel';
export const OBJECT_CLASS_DISPLAY_ENABLE_RECORD_VIEW =
  'object-class-display-enable-record-view';
export const OBJECT_CLASS_DISPLAY_HIDE_SUBMIT =
  'object-class-display-hide-subnmit';
export const OBJECT_CLASS_FIELD_LABEL = 'object-class-field-label';
export const OBJECT_CLASS_FIELD_ALIAS = 'object-class-field-alias';
export const OBJECT_CLASS_PERMISSION_SUBTAB =
  'object-class-permissions-subtab-';
export const OBJECT_CLASS_PERMISSIONS_SWITCH =
  'object-class-permissions-switch-';
export const OBJECT_CLASS_PERMISSIONS_ADD_OWNERS =
  'object-class-permissions-add-owners';
export const OBJECT_CLASS_PERMISSIONS_ADD_ASSIGNEES =
  'object-class-permissions-add-assignees';
export const OBJECT_CLASS_PERMISSIONS_MANAGE_OWNERS =
  'object-class-permissions-manage-owners';
export const MANAGE_ASSIGNEES_PANEL_BUTTON_ADD_ASSIGNEES =
  'object-class-permissions-add-assignees-link';
export const OBJECT_CLASS_PERMISSIONS_MANAGE_ASSIGNEES =
  'object-class-permissions-manage-assignees';
export const MANAGE_OWNERS_PANEL_BUTTON_ADD_OWNERS =
  'manage-owners-panel-button-add-owners-link';
export const ADD_OWNERS_PANEL_COUNTER = 'add-owners-panel-counter';
export const ADD_PERMISSION_SET_TEST_ID = 'add-permission-set';
export const PERMISSION_SET_EDITED_COLUMN_TEST_ID =
  'permission-set-edited-column';
export const PERMISSION_SET_COLUMN_MENU_BUTTON_TEST_ID =
  'permission-set-column-menu-button';
export const DISPLAY_TABLE_MENU_BUTTON_TEST_ID = 'display-table-menu-button';
export const OBJECT_CLASS_DISPLAY_TITLE_TEST_ID = 'object-class-display-title';
export const URL_INPUT_PROTOCOL = 'url-input-protocol';
export const URL_INPUT_URL = 'url-input-url';

export const AUTOCOMPLETE_USERS_SELECT = 'autocomplete-users-select';

export const LIST_TEST_ID = 'list';
export const USERS_LIST_ITEM_TEST_ID = 'users-list-item';
export const USERS_LIST_ITEM_NAME_TEST_ID = 'users-list-item_name';
export const USERS_LIST_ITEM_DELETE_BUTTON_TEST_ID =
  'users-list-item-delete-button';
export const GROUP_LIST_ITEM_TEST_ID = 'group-list-item';
export const GROUP_LIST_ITEM_NAME_TEST_ID = 'group-list-item-name';
export const GROUP_LIST_ITEM_DELETE_BTN_TEST_ID =
  'group-list-item-delete-button';

// Single Sign On
export const SINGLE_SIGN_ON_CREATE_USER = 'single-sign-on-create-user';

// Listing view
export const IDENTIFIER_FIELD = 'identifier-field';
export const PREVIEW_TITLE = 'preview-title';
export const FIELD_LABEL = 'field-label';
export const FIELD_VALUE = 'field-value';
export const RECORD_LISTING_WRAPPER = 'record-listing-wrapper';
export const ADD_NEW_RECORD_TESTID = 'add-new-record';
export const SWITCH_VIEW_BUTTON_TESTID = 'switch-view';
export const SINGLE_RECORD_TEST_ID = 'single-record';
export const OPEN_RECORD_BUTTON_TESTID = 'open-record-button';

//toast
export const TOAST_TESTID = {
  [ToastType.Success]: 'toast-success',
  [ToastType.Error]: 'toast-error',
  [ToastType.Info]: 'toast-info',
  [ToastType.Warning]: 'toast-warning',
};

//in place edit components
export const IN_PLACE_EDIT_PREVIEW = 'in-place-edit-preview';
export const IN_PLACE_EDIT_MULTISELECT_DROPDOWN = 'in-place-edit-multiselect';
export const IN_PLACE_EDIT_TEXT_INPUT = 'in-place-edit-text-input';
export const IN_PLACE_EDIT_TEXTAREA = 'in-place-edit-textarea';
export const IN_PLACE_EDIT_CHECKBOX = 'in-place-edit-checkbox';
export const IN_PLACE_EDIT_NUMERIC = 'in-place-edit-numeric';
export const IN_PLACE_EDIT_SINGLE_SELECT = 'in-place-edit-single-select';
export const IN_PLACE_EDIT_URL = 'in-place-edit-url';
export const IN_PLACE_EDIT_DATEPICKER = 'in-place-edit-datepicker';
export const IN_PLACE_EDIT_TIMEPICKER = 'in-place-edit-timepicker';
export const IN_PLACE_EDIT_EMAIL = 'in-place-edit-email';
export const IN_PLACE_EDIT_PHONE = 'in-place-edit-phone';

// Help panel
export const HELP_PANEL = 'help-panel';
export const HELP_PANEL_TITLE = 'help-panel-title';
export const HELP_PANEL_CONTENT = 'help-panel-content';
export const HELP_PANEL_DEFAULT_VIEW = 'help-panel-default-view';

//Help mapping
export const HELP_MAPPING_PAGE = 'help-mapping-page';
export const HELP_MAPPING_UPLOAD_INPUT = 'help-mapping-upload-input';
export const HELP_MAPPING_UPLOAD_BUTTON = 'help-mapping-upload-button';
export const HELP_MAPPING_EXPORT_BUTTON = 'help-mapping-export-button';
export const HELP_MAPPING_SUBMIT_BUTTON = 'help-mapping-submit-button';

// Landing page
export const LANDING_PAGE_TOOLBOX_TESTID = 'landing-page-toolbox';

// Task Templates
export const TASK_TEMPLATE_REMINER_TESTID = 'task_template_reminder';

// Outbound mail setup
export const TEST_SETTINGS_BUTTON_TESTID = 'test-settings-button';

//Avatars
export const AVATARS_GROUP = 'avatars-group';
export const AVATARS_GROUP_REST_ITEMS = 'avatars-group-rest-items';
export const AVATARS_GROUP_MORE_AVATAR = 'avatars-group-more-avatar';

//User details - avatars
export const USER_DETAILS_WRAPPER = 'user-details';
export const USER_DETAILS_FULL_NAME = 'user-details-full-name';
export const USER_DETAILS_JOB = 'user-details-job';
export const USER_DETAILS_COMPANY_NAME = 'user-details-company-name';
export const USER_DETAILS_EMAIL = 'user-details-email';
export const USER_DETAILS_AT_ICON_TESTID = 'user-details-at-icon';
export const USER_DETAILS_MOBILE_PHONE = 'user-details-mobile-phone';
export const USER_DETAILS_PHONE = 'user-details-phone';

// Tasks
export const TASKS_LIST_ITEM = 'tasks-list-item';
export const TASKS_LIST_COUNTER = 'tasks-list-counter';
export const TASKS_LIST_IDENTIFIER = 'tasks-list-identifier';
export const CREATE_TASK_DUE_DATE = 'create-task-due-date-select';
export const CREATE_TASK_BUTTON = 'create-task-button';
export const CREATE_TASK_DUE_DATE_SELECT_TESTID = 'create-task-due-date-select';
export const TASK_FORM = 'task-form';
export const COMPLETE_TASK_FORM_BUTTON = 'complete-task-form-button';
export const SAVE_PROGRESS_TASK_FORM_BUTTON = 'save-progress-task-form-button';
export const CANCEL_TASK_FORM_BUTTON = 'cancel-task-form-button';
export const COMPLETE_TASK_BUTTON = 'complete-task-button';
export const VIEW_TASK_BUTTON = 'view-task-button';
export const TASK_COMPLETE_DATE = 'task-complete-date';
export const TASK_COMPLETED_BY = 'task-completed-by';
export const TASK_STATUS = 'task-status';
export const INCOMPLETE_TASK_DUE_DATE = 'incomplete-task-due-date';
export const TASK_PROPERTY_OWNER = 'task-property-owner';
export const TASK_PROPERTY_ASSIGNEES = 'task-property-assignees';
export const TASK_PROPERTY_COMPLETED = 'task-property-completed';
export const TASK_PROPERTY_COMPLETED_BY = 'task-property-completed-by';
export const TASK_PROPERTY_EXPIRY = 'task-property-expiry';
export const TASK_ACTION_MENU = 'task-action-menu';
export const ADD_ASSIGNEES_BTN = 'add-assignes-button';
export const TASK_OWNER_AVATAR = 'task-owner-avatar';
export const TASK_OWNER_MODAL_TITLE = 'task-owner-modal-title';
export const TASK_OWNER_ASSIGN_BUTTON = 'task-owner-assign';
export const TASK_QUICK_FILTER_OWNERSHIP = 'task-quick-filter-ownership';
export const TASK_1TC_HEADER = 'task-1tc-header';
export const TASK_1TC_INSTRUCTIONS = 'task-1tc-instructions';
export const TASK_1TC_SAVE_PROGRESS_DESCRIPTION =
  'task-1tc-save-progress-description';
export const TASK_1TC_SAVE_PROGRESS_OK = 'task-1tc-save-progress-ok';
export const TASK_1TC_SUMMARY = 'task-1tc-summary';

export const TASK_QUICK_FILTER_DATES = 'task-quick-filter-dates';

export const ALERT_TYPE_TESTID = 'alert-type-';
export const ALERT_TESTID = {
  error: 'alert-error',
  info: 'alert-info',
  warning: 'alert-warning',
  success: 'alert-success',
};

// OptionAvatar
export const OPTION_AVATAR_TEXT_WRAPPER = 'option-avatar-text-wrapper';
export const OPTION_AVATAR_BOTTOM_TEXT = 'option-avatar-bottom-text';
export const OPTION_AVATAR_DISABLED_TEXT = 'option-avatar-disabled-text';

// ContentDrawer
export const CONTENT_DRAWER = 'content-drawer';
export const CONTENT_DRAWER_CLOSE_ICON = 'content-drawer-close-icon';

// overview
export const SECTION_TITLE = 'section-title-';
export const TILE_WRAPPER = 'tile-wrapper-';
export const TILE_CREATE_BUTTON = 'tile-create-button-';
export const TILE_MANAGE_BUTTON = 'tile-manage-button-';
export const TILE_CONFIGURE_BUTTON = 'tile-configure-button-';
export const TILE_BOX_VALUE = 'tile-box-value-';

// Sequences
export const SEQUENCE_NAME = 'sequence-name-field';
export const MANAGE_DEFAULT_TASK_ASSIGNEES = 'manage-default-task-assignees';
export const ADD_DEFAULT_TASK_ASSIGNEES = 'add-default-task-assignees';
export const ASSIGNEES_COUNT_ID = 'task-assignees-count';

// Administration
export const SECTION_TITLE_DATA_MODEL = 'section-title-data-model';

// Files
export const FILE_UPLOAD_INPUT = 'file-upload-input';
export const LISTED_UPLOADED_FILE = 'uploaded-file';
export const REMOVE_FILE = 'remove-single-file-btn';
export const UPLOAD_FILE_ERROR = 'upload-file-error';
export const FILES_LIST = 'files-list';
export const FILES_LIST_ITEM = 'files-list-item';
export const FILES_PREVIEW = 'files-preview';
export const FILE_ACTIONS_MENU_TESTID = 'file-actions-menu';

// CollapseWithHeaderButton
export const COLLAPSE_CONTENT = 'collapse-content';
export const COLLAPSE_WITH_HEADER_BUTTON = 'collapse-with-header-button';

// Members
export const MEMBERSHIP_TAG = 'membership-tag';
export const USER_GROUP_OWNERS_WRAPPER = 'user-group-owners-wrapper';
export const USER_GROUP_OWNERS_LIMIT_ALERT = 'user-group-owners-limit-alert';

// My account
export const MY_ACCOUNT_TAB_SAVE_BUTTON = 'my-account-tab-save-button';
export const MY_ACCOUNT_TAB_REVERT_BUTTON = 'my-account-tab-revert-button';
export const MY_ACCOUNT_SECTION = 'my-account-section-';
export const SHOW_PASSWORD_CHECKBOX = 'show-password-checkbox';
export const MY_ACCOUNT_PREFERENCES_TAB = 'my-account-preferences-tab';
export const ROW_SETTING_DENSITY_SECTION = 'row-settings-density-section';
export const LNG_AND_LOC_SECTION = 'lang-and-localisation-section';
export const LNG_AND_LOC_DATE_AND_TIME_SECTION =
  'lang-and-localisation-date-and-time-section';
export const LNG_AND_LOC_FORMATS_SECTION =
  'lang-and-localisation-formats-section';
export const LNG_AND_LOC_LANGUAGE_SECTION =
  'lang-and-localisation-language-section';
export const LNG_AND_LOC_CURRENT_PLATFORM_DATE_TIME_TEXT =
  'lang-and-localisation-current-platform-date-time-text';
export const LNG_AND_LOC_CURRENT_PLATFORM_DATE_TIME =
  'lang-and-localisation-current-platform-date-time';
export const LNG_AND_LOC_CURRENT_PLATFORM_ADDITIONAL_TEXT =
  'lang-and-localisation-current-platform-additional-text';
export const LNG_AND_LOC_CURRENT_TIMEZONE_ADDITIONAL_TEXT =
  'lang-and-localisation-current-timezone-additonal-text';
export const LNG_AND_LOC_CURRENT_SELECTED_TIMEZONE_TEXT =
  'lang-and-localisation-current-selected-timezone-text';
export const LNG_AND_LOC_CURRENT_SELECTED_TIMEZONE_TIME =
  'lang-and-localisation-current-selected-timezone-time';
export const DENSITY_PREVIEW_TABLE_HEAD = 'density-preview-table-head';
export const DENSITY_PREVIEW_TABLE_ROW = 'density-preview-table-row';

// Authentication object
export const AUTH_OBJECT_TEST_BUTTON = 'auth-object-test-button';
export const AUTH_OBJECT_URL_KEY = 'auth-object-url-key';
export const AUTH_OBJECT_HEADER_FIELD_NAME = 'auth-object-header-field-name';

// Class forms
export const CLASS_FORM_COMPLETE_BUTTON = 'complete-class-form';

// Document Templates
export const DOCUMENT_TEMPLATES_DESCRIPTION = 'document-templates-description';
export const DOCUMENT_TEMPLATES_NAME = 'document-templates-name';
export const DOCUMENT_TEMPLATES_ENABLE_MANUAL_GENERATION =
  'document-templates-enable-manual-generation';
export const DOCUMENT_TEMPLATES_UPLOAD = 'document-templates-upload';
export const CLICKABLE_LINK = 'clickable-link';

// Document Templates Panel
export const DOCUMENT_TEMPLATES_PANEL_CHECK_ICON =
  'document-templates-panel-check-icon';
export const DOCUMENT_TEMPLATES_PANEL_DOWNLOAD_ICON =
  'document-templates-panel-download-icon';
export const DOCUMENT_TEMPLATES_PANEL_LOADER_ICON =
  'document-templates-panel-loader-icon';
export const DOCUMENT_TEMPLATES_PANEL_CANCEL_ICON =
  'document-templates-panel-cancel-icon';
export const DOCUMENT_TEMPLATES_PANEL_SAVE_MODAL =
  'document-templates-panel-save-icon';
export const DOCUMENT_TEMPLATES_PANEL_SAVE_FIELD =
  'document-templates-panel-save-field';
export const DOCUMENT_TEMPLATES_PANEL_CANCEL =
  'document-templates-panel-cancel';
export const DOCUMENT_TEMPLATES_PANEL = 'document-templates-panel';

// Image upload
export const IMAGE_UPLOAD_INPUT = 'image-upload-input';
export const IMAGE_UPLOAD_PREVIEW_IN_PROGRESS =
  'image-upload-preview-in-progress';
export const IMAGE_UPLOAD_FILENAME_IN_PROGRESS =
  'image-upload-filename-in-progress';
export const IMAGE_UPLOAD_PREVIEW_COMPLETE = 'image-upload-preview-complete';
export const IMAGE_UPLOAD_FILENAME_COMPLETE = 'image-upload-filename-complete';

// Activity log
export const EVENT_DATA_LABEL = 'event-data-label';
export const EVENT_DATA_CHIP = 'event-data-chip';
export const EVENT_DATA_EXPAND = 'event-data-expand';
export const EVENT_TYPE_FILTER = 'event-type-filter';
export const EVENT_FIELD_FILTER = 'event-field-filter';
export const EVENT_DATE_FILTER = 'event-date-filter';
export const EVENT_DATA_LINK = 'event-link-';

// Theme
export const THEME_FORM_FIELD_LABEL = 'theme-form-field-label';
export const THEME_FORM_FIELD_DESCRIPTION = 'theme-form-field-description';
export const THEME_TAB_MEDIA = 'theme-tab-media';
export const THEME_TAB_TEXT_OVERRIDES = 'theme-tab-text-overrides';
export const THEME_TAB_COLOURS = 'theme-tab-colours';
export const THEME_INPUT_HEADINGTEXT = 'multi-line-input-headingText';
export const THEME_INPUT_BODYTEXT = 'multi-line-input-bodyText';

//ChildClassComponent
export const CHILD_CLASS_COMPONENT_CREATE_BUTTON =
  'child-class-component-create-button';
export const CHILD_CLASS_COMPONENT_LAST_PAGE =
  'child-class-component-last-page';
export const CHILD_CLASS_COMPONENT_FIRST_PAGE =
  'child-class-component-first-page';
export const CHILD_CLASS_COMPONENT_NEXT_PAGE =
  'child-class-component-next-page';
export const CHILD_CLASS_COMPONENT_PREVIOUS_PAGE =
  'child-class-component-previous-page';
export const CHILD_CLASS_COMPONENT_PAGINATION_INPUT =
  'child-class-component-pagination-input';
export const CHILD_CLASS_COMPONENT_SEARCHBOX =
  'child-class-component-searchbox';
export const CHILD_CLASS_TABLE_WRAPPER = 'child-class-table-wrapper';
export const CHILD_CLASS_AUTOCOMPLETE_SELECT =
  'child-class-table-autocomplete-select';
export const CHILD_CLASS_COMPONENT_CLEAR_BUTTON =
  'CHILD_CLASS_COMPONENT_CLEAR_BUTTON';

//Relational Modeller
export const RELATIONAL_MODEL_NODE_CARD_BODY_INNER =
  'relational-model-node-card-body-inner';
export const RELATIONAL_MODEL_NODE = 'relational-model-node';
export const RELATIONAL_MODEL_NODE_TOGGLE_COLLAPSE =
  'relational-model-node-toggle-collapse';
export const RELATIONAL_MODEL_NODE_TOGGLE_MULTIPLICITY =
  'relational-model-node-toggle-multiplicity';
export const RELATIONAL_MODEL_NODE_DELETE_BUTTON =
  'relational-model-node-delete-button';
//CUSTOM COMPONENTS
export const SCROLLABLE_EXTERNAL_COMPONENTS_CONTAINER =
  'external_infinity_scroll';
export const CUSTOM_COMPONENTS_PUBLISHER = 'custom-components-publisher';
export const CUSTOM_COMPONENTS_VERSION = 'custom-components-version';

export const HEIGHT_INPUT_TESTID = 'height-input';

// USER GROUP PERMISSION SETS
export const USERG_GROUP_PERMISSION_SETS_PERMISSION_LABEL =
  'user-group-permission-sets-permission-label-';

export const USERG_GROUP_PERMISSION_SETS_PERMISSION_SET_NAME =
  'user-group-permission-sets-permission-set-name-';

// USERS
export const USER_ACCOUNT_TYPE_CARD = 'user-account-type-card-';

// Viewer Panel
export const VIEWER_PANEL_RECORD_IDENTIFIER_TESTID =
  'viewer-panel-record-identifier';
export const VIEWER_PANEL_FIELD_LABEL_TESTID = 'viewer-panel-field-label';
export const VIEWER_PANEL_FILE_DOWNLOAD_TESTID = 'viewer-panel-file-download';

// Class Permission Set Manage Assignees Panel
export const CLASS_PERMISSION_MANAGE_ASSIGNEES_PANEL_TESTID =
  'class-permission-manage-assignees-panel';
export const SELECTED_PERMISSION_SET_NAME_TESTID =
  'selected-permission-set-name';
export const ADD_USER_ASSIGNEES_BTN_TESTID = 'add-user-assignees-btn';
export const ADD_GROUP_ASSIGNEES_BTN_TESTID = 'add-group-assignees-btn';

// User type field / Users and groups select
export const USERS_AND_GROUPS_SELECT_TESTID = 'users-and-groups-select';
export const USERS_AND_GROUPS_SELECT_WRAPPER_TESTID =
  'users-and-groups-select-wrapper';
export const USERS_AND_GROUPS_SELECT_DROPDOWN_TESTID =
  'users-and-groups-select-dropdown';
export const USERS_AND_GROUPS_SELECT_GROUP_ITEM_ARROW_TESTID =
  'expandable-picker-user-group-item-arrow';
export const USERS_AND_GROUPS_SELECT_VALIDATION_CONTAINER_TESTID =
  'users-and-groups-select-validation-container';
export const USERS_AND_GROUPS_SELECT_EDIT_MODE_REJECT_BUTTON_TESTID =
  'users-and-groups-select-edit-mode-reject-button';
export const USERS_AND_GROUPS_SELECT_MAIN_DROPDOWN_CONTENT_TESTID =
  'users-and-groups-select-main-dropdown-content';
export const USERS_AND_GROUPS_SELECT_OPTIONS_VIEWPORT_TESTID =
  'users-and-groups-select-options-viewport';
export const USERS_AND_GROUPS_SELECT_EXPANDED_DROPDOWN_CONTAINER_TESTID =
  'users-and-groups-select-expanded-dropdown-container';
export const USERS_AND_GROUPS_EXPANDED_SEARCH_FIELD_TESTID =
  'uag-expanded-search-field';
export const USERS_AND_GROUPS_EXPANDED_SYNC_GROUP_TOGGLE_TESTID =
  'uag-expanded-sync-group-toggle';
export const USERS_AND_GROUPS_EXPANDED_USERS_LIST_TESTID =
  'uag-expanded-users-list';
export const USERS_AND_GROUPS_FIELD_EDIT_CONTENT_TESTID = 'users-editcontent-';
export const USERS_AND_GROUPS_SELECT_ITEMS_LIST_TESTID =
  'users-and-groups-select-items-list';
export const USERS_AND_GROUPS_SELECT_ITEM_GROUP_TYPE_TESTID =
  'users-and-groups-select-item-group-type';
export const USERS_AND_GROUPS_SELECT_ITEM_SELECT_AND_DESELECT_TYPE_TESTID =
  'users-and-groups-select-item-select-and-deselect-type';

export const EDIT_SELECTED_USERS_AND_GROUPS_BTN_TESTID =
  'edit-selected-users-and-groups-btn';
