import { createContext, useContext } from 'react';
import { TasksPanelContextProps } from './TasksPanelContext.types';

export const TasksPanelContext = createContext<TasksPanelContextProps | null>(
  null
);

export const useTasksPanelContext = () => {
  const context = useContext(TasksPanelContext);

  if (!context) {
    throw new Error(
      "Encountered null context. Did you wrap your component with context's provider?"
    );
  }

  return context;
};
