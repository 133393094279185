import React, { useCallback, useEffect, useState } from 'react';
import { TaskList } from './components/TaskList';
import { CreateTask } from './components/CreateTask';
import { ReassignTask } from './components/ReassignTask';
import { AddTaskAssignees } from './components/AddTaskAssignees';
import { Task } from 'utils/types/api/tasks.types';
import { useSelectedResourceContext } from 'contexts/SelectedResourceContext';
import { TasksPanelProvider } from './contexts/TasksPanelContext';
import { StageDetails, TasksPanelMode } from './types';
import { useTasksPanelStyles } from './TasksPanel.styles';

export const TasksPanel = () => {
  const styles = useTasksPanelStyles();
  const {
    selectedResource: { record: { recordId = undefined } = {} } = {},
  } = useSelectedResourceContext();

  const [mode, setMode] = useState<TasksPanelMode>(TasksPanelMode.List);
  const [task, setTask] = useState<Task | undefined>();
  // later will be more stage so that's why we need to store it in context
  const [stage, setStage] = useState<StageDetails | undefined>();

  const Panel = useCallback(() => {
    switch (mode) {
      case TasksPanelMode.Create: {
        return <CreateTask />;
      }
      case TasksPanelMode.Reassign: {
        return <ReassignTask />;
      }
      case TasksPanelMode.AddAssignees: {
        return <AddTaskAssignees />;
      }
      default:
        return <TaskList />;
    }
  }, [mode]);

  /**
   * Resets mode to initial after new record has been selected
   */
  useEffect(() => {
    setMode(TasksPanelMode.List);
  }, [recordId]);

  return (
    <div className={styles.container}>
      <TasksPanelProvider
        task={task}
        stage={stage}
        setMode={setMode}
        setTask={setTask}
        setStage={setStage}
      >
        <Panel />
      </TasksPanelProvider>
    </div>
  );
};
