import React, { useState } from 'react';
import { Formik } from 'formik';
import { TASK_FORM } from 'utils/testIds';
import { TaskFormField } from '../../../../types';
import useFormHeader from 'components/FormHeader/hooks';
import { Loader } from 'components/lib/Loader';
import ErrorComponent from 'components/ErrorComponent';
import { useTaskOptions } from '../../../../hooks/useTaskOptions';
import { useTaskFormSubmit } from './hooks';
import { TaskFormType, DueDateOption, TaskFormSchema } from './types';
import { useTaskFormStyles } from './TaskForm.styles';
import useValidationSchemaBuilder from 'hooks/useValidationSchemaBuilder';
import { useTaskUserAssigneeOptions } from 'components/SidePanels/TasksPanel/hooks/useTaskUserAssigneeOptions';
import { createTaskFormSchema } from './utils';
import { TASK_FORM_INITIAL_VALUES } from './constants';
import { TaskFormTaskTypeField } from './components/TaskFormTaskTypeField';
import { TaskFormTaskTemplateField } from './components/TaskFormTaskTemplateField';
import { TaskFormNameField } from './components/TaskFormNameField';
import { TaskFormDueDateField } from './components/TaskFormDueDateField';
import { TaskFormFooter } from './components/TaskFormFooter';
import { TaskFormAssigneesSection } from './components/TaskFormAssigneesSection';
import { TaskFormInstructionsSection } from './components/TaskFormInstructionsSection';
import { CancelTaskCreationModals } from './components/CancelTaskCreationModals';
import { useTaskGroupAssigneeOptions } from 'components/SidePanels/TasksPanel/hooks/useTaskGroupAssigneeOptions';

export const TaskForm = () => {
  const styles = useTaskFormStyles();

  const { toggleCancelModal, ...modalProps } = useFormHeader();
  const { onSubmit } = useTaskFormSubmit();
  const {
    options: taskOptions,
    areOptionsLoading: areTaskOptionsLoading,
    optionsError: taskOptionsError,
  } = useTaskOptions();
  const {
    options: taskUserAssigneeOptions,
    areOptionsLoading: areTaskUserAssigneeOptionsLoading,
    optionsError: taskUserAssigneeOptionsError,
  } = useTaskUserAssigneeOptions();
  const {
    options: taskGroupAssigneeOptions,
    areOptionsLoading: areTaskGroupAssigneeOptionsLoading,
    optionsError: taskGroupAssigneeOptionsError,
  } = useTaskGroupAssigneeOptions();

  const formSchema = createTaskFormSchema(
    taskOptions,
    taskUserAssigneeOptions,
    taskGroupAssigneeOptions
  );

  const {
    rawValidationSchema,
    buildValidationSchema,
  } = useValidationSchemaBuilder<TaskFormSchema>(formSchema);

  const [dueDateOption, setDueDateOption] = useState<DueDateOption>(
    DueDateOption.NoDueDate
  );

  if (
    areTaskOptionsLoading ||
    areTaskUserAssigneeOptionsLoading ||
    areTaskGroupAssigneeOptionsLoading
  ) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  if (
    taskOptionsError ||
    taskUserAssigneeOptionsError ||
    taskGroupAssigneeOptionsError
  ) {
    return (
      <ErrorComponent
        error={
          taskOptionsError?.status ??
          taskUserAssigneeOptionsError?.status ??
          taskGroupAssigneeOptionsError?.status
        }
      />
    );
  }

  return (
    <Formik<TaskFormType>
      initialValues={TASK_FORM_INITIAL_VALUES}
      enableReinitialize
      validationSchema={buildValidationSchema()}
      onSubmit={onSubmit}
    >
      <div className={styles.formWrapper} data-testid={TASK_FORM}>
        <div>
          <TaskFormNameField
            className={styles.formField}
            isRequired={
              rawValidationSchema[TaskFormField.Name]?.required ?? false
            }
            minLength={rawValidationSchema[TaskFormField.Name]?.min_length}
            maxLength={rawValidationSchema[TaskFormField.Name]?.max_length}
          />

          <TaskFormTaskTypeField
            className={styles.formField}
            options={formSchema?.[TaskFormField.TaskType].values ?? []}
            taskTypeSpecificOptions={
              formSchema?.[TaskFormField.TaskType].taskTypeSpecificOptions
            }
            isRequired={
              rawValidationSchema[TaskFormField.TaskType]?.required ?? false
            }
          />

          <TaskFormTaskTemplateField
            className={styles.formField}
            taskTypeSpecificOptions={
              formSchema?.[TaskFormField.TaskType].taskTypeSpecificOptions
            }
            isRequired={
              rawValidationSchema[TaskFormField.TaskTemplate]?.required ?? false
            }
          />

          <TaskFormDueDateField
            rawValidationSchema={rawValidationSchema}
            dueDateOption={dueDateOption}
            setDueDateOption={setDueDateOption}
          />

          <TaskFormAssigneesSection formSchema={formSchema} />

          <TaskFormInstructionsSection />
        </div>

        <TaskFormFooter
          shouldDueDateHaveValue={dueDateOption === DueDateOption.DueDate}
          onCancelWhenFormDirty={() => toggleCancelModal(true)}
        />

        <CancelTaskCreationModals
          isCancelModalVisible={modalProps.isCancelModalVisible}
          isNavigateAway={modalProps.isNavigateAway}
          toggleCancelModal={toggleCancelModal}
        />
      </div>
    </Formik>
  );
};
