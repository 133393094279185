import { createUseStyles } from 'react-jss';
import { panelWrapper } from 'styles/panelsStyles';
import { ThemeState } from 'store/reducers/types/theme.types';

export const useAddAssigneesStyles = createUseStyles((theme: ThemeState) => ({
  reassigneTaskPanelWrapper: {
    ...panelWrapper,
    padding: '15px 0',
    '& .ant-collapse-header': {
      margin: '0px 0px 10px',
    },
  },
  subtitle: {
    fontWeight: 700,
    fontSize: 16,
    lineHeight: '22px',
    color: theme['heading-color'],
    padding: '0 15px',
    margin: 0,
  },
  listWrapper: {
    height: '100%',
    overflow: 'auto',
  },
}));
