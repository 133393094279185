import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormLabel from 'pages/TaskTemplates/components/FormLabel';
import { TaskFormAssigneesSectionProps } from './TaskFormAssigneesSection.types';
import { useTaskAssigments } from './hooks';
import { TaskFormField } from 'components/SidePanels/TasksPanel/types';
import { useTaskFormAssigneesSectionStyles } from './TaskFormAssigneesSection.styles';
import { FUsersAndGroupsSelect } from 'components/UsersAndGroupsSelection/FUsersAndGroupsSelect';
import { useFormikContext } from 'formik';
import { TaskFormType } from '../../types';
import Tooltip from 'components/lib/Tooltip';
import { SelectedUsersAndGroupsEditor } from 'components/SelectedUsersAndGroupsEditor';

export const TaskFormAssigneesSection = ({
  formSchema,
}: TaskFormAssigneesSectionProps) => {
  const styles = useTaskFormAssigneesSectionStyles();

  const { values } = useFormikContext<TaskFormType>();
  const {
    disabledSelectText,
    is1TCUserSelected,
    checkShouldOptionBeDisabled,
    handleUserDelete,
    handleGroupDelete,
  } = useTaskAssigments();

  const selectedUsers = values[TaskFormField.Assignees].users;
  const selectedGroups = values[TaskFormField.Assignees].groups;
  const isAssigneesFieldRequired =
    formSchema?.[TaskFormField.Assignees].required ?? false;

  return (
    <div className={styles.contentWrapper}>
      <FormLabel bolded={false} required={isAssigneesFieldRequired}>
        <FormattedMessage
          id='tasksPanel.form.taskAssignees'
          defaultMessage='Task assignees'
        />
      </FormLabel>

      <div className={styles.fieldWrapper}>
        <Tooltip
          title={disabledSelectText}
          shouldBeRendered={is1TCUserSelected}
        >
          <FUsersAndGroupsSelect
            name={TaskFormField.Assignees}
            isDisabled={is1TCUserSelected}
            usersAutocompleteUrl={
              formSchema?.[TaskFormField.Assignees].usersAutocompleteUrl
            }
            groupsAutocompleteUrl={
              formSchema?.[TaskFormField.Assignees].groupsAutocompleteUrl
            }
            checkShouldOptionBeDisabled={checkShouldOptionBeDisabled}
          />
        </Tooltip>

        <SelectedUsersAndGroupsEditor
          selectedUsers={selectedUsers}
          selectedGroups={selectedGroups}
          onUserDelete={handleUserDelete}
          onGroupDelete={handleGroupDelete}
        />
      </div>
    </div>
  );
};
